import React, { useRef } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';

const FileUploadInput = ({ onChange }: { onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e); // Call the provided onChange handler

    // Reset the input field after processing the file
    if (inputRef.current) {
      inputRef.current.value = ''; // Clear the input value to allow selecting the same file again
    }
  };

  return (
    <div className='form-group'>
      <label htmlFor='fileInput' className='btn btn-sm btn-light-primary m-1'>
        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
        Upload CSV
      </label>
      <input
        ref={inputRef}
        id='fileInput'
        type='file'
        accept='.csv'
        className='form-control d-none'
        onChange={handleFileChange} // Use handleFileChange to handle file selection
      />
    </div>
  );
};

export default FileUploadInput;
