import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { IUserListDetails, IUserRoleListDetails, userListDetailsInitValues as initialValues } from './User';
import { usersService } from '../../../services/users.service';
import { lookupService } from '../../../services/lookup.service';
import { useAuth } from '../auth';
import clsx from 'clsx';


const userDetailsSchema = (isEdit: boolean) =>
  Yup.object().shape({
    FirstName: Yup.string().required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    Email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    Role: Yup.string().required('Role is required'),
    Password: isEdit
      ? Yup.string()
      : Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
  });

interface Props {
  usersData?: IUserListDetails;
  userId?: number;
}

const UsersAdd: FC<Props> = ({ usersData }) => {
  const [loading, setLoading] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const [formData, setFormData] = useState<IUserListDetails>({ ...initialValues, IsEnable: !userId });
  const [roles, setRoles] = useState<IUserRoleListDetails[]>([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [actualPassword, setActualPassword] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await usersService.getUserById(userId);
        formik.setFieldValue('FirstName', response.FirstName);
        formik.setFieldValue('LastName', response.LastName);
        formik.setFieldValue('Email', response.Email);
        formik.setFieldValue('Role', response.UserRoleID);
        formik.setFieldValue('IsEnable', response.IsEnable);
        if (currentUser?.IsSuperAdmin) {
          formik.setFieldValue('Password', response.Password);
          setActualPassword(response.Password);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching userlist details:', error);
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserDetails();
    }
    fetchRoles();
  }, [userId]);

  const fetchRoles = async () => {
    try {
      const rolesResponse = await lookupService.getAllRoles();
      setRoles(rolesResponse.roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const initialValuesForForm = usersData ? usersData : formData;

  const formik = useFormik<IUserListDetails>({
    initialValues: initialValuesForForm,
    validationSchema: userDetailsSchema(!!userId),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (userId) {
          values.ID = Number(userId);
        }
        const storedOrganization = localStorage.getItem('selectedOrganization');
        const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
        values.OrgId = OrgId;

        const response = await usersService.userAddEdit(values);
        console.log('user added/edited successfully:', response);
        formik.resetForm();
        setLoading(false);
        navigate('/users');
      } catch (error) {
        console.error('Error adding/editing user:', error);
        setLoading(false);
      }
    },
  });

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setActualPassword(newPassword);
    formik.setFieldValue('Password', newPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const getMaskedPassword = () => {
    return '*'.repeat(actualPassword.length);
  };

  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <form onSubmit={formik.handleSubmit} noValidate className='form' style={{ backgroundColor: 'white' }}>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        {...formik.getFieldProps('FirstName')}
                      />
                      {formik.touched.FirstName && formik.errors.FirstName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.FirstName}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Last name'
                        {...formik.getFieldProps('LastName')}
                      />
                      {formik.touched.LastName && formik.errors.LastName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.LastName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    {...formik.getFieldProps('Email')}
                  />
                  {formik.touched.Email && formik.errors.Email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Email}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    {...formik.getFieldProps('Role')}
                  >
                    <option value=''>Select Role</option>
                    {roles.map((role) => (
                      <option key={role.ID} value={role.ID}>
                        {role.Name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.Role && formik.errors.Role && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Role}</div>
                    </div>
                  )}
                </div>
              </div>

              {!userId || currentUser?.IsSuperAdmin ? (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>
                    <div className='col-lg-8 fv-row'>
                      <div className='input-group'>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          autoComplete='off'
                          value={actualPassword}
                          onChange={handlePasswordChange}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.Password && formik.errors.Password,
                            },
                            {
                              'is-valid': formik.touched.Password && !formik.errors.Password,
                            }
                          )}
                        />
                        <button
                          type='button'
                          className='btn btn-outline-secondary'
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? 'Hide' : 'Show'}
                        </button>
                      </div>
                      {formik.touched.Password && formik.errors.Password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.Password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              {userId && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>
                  <div className='col-lg-8 d-flex align-items-center'>
                    <div className='form-check form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id='allowmarketing'
                        checked={formik.values.IsEnable}
                        onChange={(e) => {
                          formik.setFieldValue('IsEnable', e.target.checked);
                        }}
                      />
                      <label className='form-check-label'></label>
                      {formik.touched.IsEnable && formik.errors.IsEnable && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.IsEnable}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { UsersAdd };
