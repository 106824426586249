/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { visitorsLogService } from '../../../../services/visitorslog.service';
import { Visitor, VisitorLog, VisitorTypes } from '../../../../app/modules/apps/invites/components/VisitorModel';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from 'date-fns';
import { useAuth } from '../../../../app/modules/auth';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap-daterangepicker';
import $ from 'jquery';
// TypeScript Declaration for daterangepicker
interface JQuery {
  daterangepicker(options?: any, callback?: (start: { toDate: () => Date }, end: { toDate: () => Date }) => void): JQuery;
}


type Props = {
  className: string
}

const TablesVisitLog: React.FC<Props> = ({ className }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [visitorsLog, setVisitorsLog] = useState<VisitorLog[]>([]);
  const [visitorTypes, setVisitorTypes] = useState<VisitorTypes[]>([]);
  const [selectedVisitorType, setSelectedVisitorType] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  // const pageSize = 2; // Number of entries per page
  const [searchInput, setSearchInput] = useState<string>('');

  const [startDate, setStartDate] = useState<Date | null>(addMonths(new Date(), -1)); // Default to previous month
  const [endDate, setEndDate] = useState<Date | null>(new Date()); // Default to current date
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);

  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<string>('FirstName');
  const [pageSize, setPageSize] = useState<number>(15); // Number of entries per page
  const { currentUser, logout } = useAuth()
  const [loading, setLoading] = useState<boolean>(true);


  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(prevSortDirection => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  useEffect(() => {
    fetchVisitorsLog();
  }, [currentPage, selectedVisitorType, startDate, endDate, pageSize]); // Fetch visitors whenever the page changes or the visitor type selection changes

  const fetchVisitorsLog = async () => {
    try {
      if (startDate != null && endDate != null) {
        setLoading(true);
        setVisitorsLog([]);
        const storedOrganization = localStorage.getItem('selectedOrganization');
        const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
        const data = await visitorsLogService.getAllVisitorsLog(currentPage, pageSize, startDate, endDate, OrgId);
        setVisitorsLog(data.visitorsLog);
        setVisitorTypes(data.visitorTypes);
        setTotalEntries(data.totalEntries);
      }
    } catch (error) {
      console.error('Error fetching visitors logs:', error);
    } finally {
      setLoading(false); // Set loading back to false after fetching data
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(totalEntries / pageSize);
  const paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
        <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
      </li>
    );
  }
  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  const filterVisitors = () => {
    let filteredVisitors = visitorsLog;

    // If search input is provided, filter visitors based on first name or last name
    if (searchInput) {
      filteredVisitors = visitorsLog.filter((log) => {
        const fullName = `${log.FirstName} ${log.LastName}`.toLowerCase();
        return fullName.includes(searchInput.toLowerCase());
      });
    }

    if (selectedVisitorType) {
      filteredVisitors = filteredVisitors.filter(visitor => Number(visitor.VisitorTypeID) === Number(selectedVisitorType));
    }

    filteredVisitors.sort((a: any, b: any) => {
      const columnA = a[sortColumn].toLowerCase();
      const columnB = b[sortColumn].toLowerCase();
      if (columnA < columnB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (columnA > columnB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return filteredVisitors;
  };

  // Function to convert table data into CSV format
  const convertToCSV = () => {
    const csvRows = [];
    const headers = ['First Name', 'Last Name', 'Email', 'Phone', 'Visitor Type', 'Check In', 'Check Out', 'Location', 'NDA']; // Add more headers as needed
    csvRows.push(headers.join(','));

    visitorsLog.forEach((visitor) => {
      const values = [
        visitor.FirstName,
        visitor.LastName,
        visitor.Email,
        visitor.Phone,
        visitor.visitorTypeName,
        visitor.CheckIn,
        visitor.CheckOut,
        visitor.LocationName,
        visitor.DocumentType
      ]; // Add more values as needed
      csvRows.push(values.join(','));
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', 'visitors.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      const allIds = visitorsLog.map(visit => visit.ID);
      setSelectedCheckboxes(allIds);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const isCheckboxSelected = (visitorId: number) => {
    return selectedCheckboxes.includes(visitorId);
  };

  const toggleCheckbox = (visitorId: number) => {
    if (selectedCheckboxes.includes(visitorId)) {
      setSelectedCheckboxes(prevState => prevState.filter(id => id !== visitorId));
    } else {
      setSelectedCheckboxes(prevState => [...prevState, visitorId]);
    }
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing page size
  };

  const handleStorageChange = () => {
    setVisitorsLog([]);
    fetchVisitorsLog();
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // Initialize date range picker
    const $daterangepicker = $('#daterangepicker') as any;
    $daterangepicker.daterangepicker({
      startDate: startDate ? moment(startDate) : moment().subtract(1, 'months'),
      endDate: endDate ? moment(endDate) : moment(),
      locale: {
        format: 'YYYY-MM-DD',
        separator: ' to '
      },
    }, (start: { toDate: () => Date }, end: { toDate: () => Date }) => {
      setStartDate(start.toDate());
      setEndDate(end.toDate());
    });

    return () => {
      // Clean up date range picker
      const picker = $daterangepicker.data('daterangepicker') as any;
      if (picker) {
        picker.remove();
      }
    };
  }, [startDate, endDate]);
  function formatDateTime(dateString: string): React.ReactNode {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // convert to 12-hour format, '0' should be '12'

    return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className='d-flex align-items-center position-relative me-4'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-3 position-absolute ms-3'
          />
          <input
            type='text'
            id='kt_filter_search'
            className='form-control form-control-white form-control-sm ps-9 me-5 w-600px'
            placeholder='Search'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)} // Update search input value
          />
          <input
            id="daterangepicker"
            type="text"
            className="form-control btn-light-primary w-400px"
            value={startDate && endDate ? `${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}` : 'Select Date Range'}
            readOnly
          />
          {/* <div id="daterangepicker" className="btn btn-light-primary w-75">
            {startDate && endDate ? `${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}` : 'Select Date Range'}
          </div> */}
          {/* <DatePicker
            showIcon
            className='form-control form-control-white w-225px'
            selected={startDate}
            onChange={onChange}
            maxDate={addMonths(new Date(), 5)}
            startDate={startDate}
            endDate={endDate}
            selectsRange
          /> */}
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm m-5'
            defaultValue=''
            onChange={(e) => setSelectedVisitorType(e.target.value)}
          >
            <option value=''>ALL</option>
            {visitorTypes.map((visitorType) => (
              <option key={visitorType.ID} value={visitorType.ID}>
                {visitorType.Name}
              </option>
            ))}
          </select>
        </div>
        {/* Export CSV button */}
        <div className='card-toolbar'>
          <button className='btn btn-sm btn-light-primary' onClick={convertToCSV}>
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Export CSV
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Display loader if loading */}
            {loading && (
              <tr>
                <td colSpan={9} style={{ textAlign: 'center' }}>
                  <img src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" alt="Loading..." />
                </td>
              </tr>
            )}
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAll}
                    />
                  </div>
                </th>
                {/* <th className='min-w-150px'>Authors</th> */}
                <th className='min-w-140px' onClick={() => handleSort('FirstName')}>
                  First Name
                  {sortColumn === 'FirstName' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-140px' onClick={() => handleSort('LastName')}>
                  Last Name
                  {sortColumn === 'LastName' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-140px' onClick={() => handleSort('Email')}>
                  Email
                  {sortColumn === 'Email' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-140px'>Phone</th>
                <th className='min-w-140px' onClick={() => handleSort('visitorTypeName')}>
                  Visitor Type
                  {sortColumn === 'visitorTypeName' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-120px'>Checked In</th>
                <th className='min-w-120px'>Checked Out</th>
                <th className='min-w-100px' onClick={() => handleSort('LocationName')}>
                  Location
                  {sortColumn === 'LocationName' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('DocumentType')}>
                  NDA
                  {sortColumn === 'DocumentType' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filterVisitors().map((visitor: VisitorLog) => (
                <tr key={visitor.ID}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value={visitor.ID.toString()}
                        // checked={selectAll}
                        checked={isCheckboxSelected(visitor.ID)}
                        onChange={() => toggleCheckbox(visitor.ID)}
                      />
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {visitor.FirstName}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {visitor.LastName}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {visitor.Email}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {visitor.Phone}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {visitor.visitorTypeName}
                    </span>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>
                        {/* {visitor.CheckIn ? moment(visitor.CheckIn).format('D MMM YYYY h:mm A') : '-'} */}
                        {visitor.CheckIn ? formatDateTime(visitor.CheckIn) : '-'}

                      </span>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>
                        {visitor.CheckOut ? formatDateTime(visitor.CheckOut) : '-'}
                        {/* {visitor.CheckOut ? moment(visitor.CheckOut).format('D MMM YYYY h:mm A') : '-'} */}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {visitor.LocationName}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {visitor.DocumentType}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {/* Pagination */}
      <div className="d-flex flex-stack flex-wrap pt-10 justify-content-lg-start m-5">
        <div className="fs-6 fw-bold text-gray-700 me-3">view records per page </div>
        <div className="me-3">
          <select
            className="form-select form-select-sm"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
          </select>
        </div>

        <ul className="pagination p-5">
          {/* Previous button */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
          </li>
          {/* Page numbers */}
          {paginationItems}
          {/* Next button */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export { TablesVisitLog }
