import { BehaviorSubject } from 'rxjs';
import getConfig from 'next/config';

import { fetchWrapper } from '../helpers';

const API_BASE_URL = process.env.REACT_APP_API_URL


export const invitationService = {
    getAllInvitation,
    getInvitationById,
    invitationAddEdit,
    deleteInvitation,
    emailExist,
};


async function getAllInvitation(currentPage, pageSize, OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/invitation/list?page=${currentPage}&pageSize=${pageSize}&OrgId=${OrgId}`);
}

async function getInvitationById(invitationId) {
    return await fetchWrapper.get(`${API_BASE_URL}/invitation/getbyid?id=${invitationId}`); // Use the base URL
}
async function emailExist(email) {
    return await fetchWrapper.get(`${API_BASE_URL}/invitation/emailexist?email=${email}`); // Use the base URL
}

async function invitationAddEdit(invitationId) {
    return await fetchWrapper.post(`${API_BASE_URL}/invitation/addedit`, invitationId); // Use the base URL
}
async function deleteInvitation(invitationId) {
    return await fetchWrapper.delete(`${API_BASE_URL}/invitation/delete?id=${invitationId}}`);
}
