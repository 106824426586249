
export interface Users {
  ID: number;
  LocationID: number;
  Email: string;
  FirstName: string;
  LastName: string;
  IsEnable: boolean;
}

export interface IUserListDetails {
  ID: number;
  LocationID: number;
  Email: string;
  Role: string;
  Password: string;
  FirstName: string;
  LastName: string;
  IsEnable: boolean;
  OrgId?: string;
}
export interface IUserRoleListDetails {
  ID: number;
  LocationID: number;
  Name: string;
  Description: string;
}

export const userListDetailsInitValues: IUserListDetails = {
  ID: 0,
  LocationID: 0,
  Email: '',
  Role: '',
  Password: '',
  FirstName: '',
  LastName: '',
  IsEnable: false,
  OrgId: ''
}