import { BehaviorSubject } from 'rxjs';
import getConfig from 'next/config';

import { fetchWrapper } from '../helpers';
const API_BASE_URL = process.env.REACT_APP_API_URL


export const visitorsService = {
    getAllVisitors,
    getVisitorById,
    visitorAddEdit,
    deleteVisitor
};


async function getAllVisitors(currentPage, pageSize, OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/visitor/list?page=${currentPage}&pageSize=${pageSize}&OrgId=${OrgId}`);
}
async function getVisitorById(visitorId) {
    return await fetchWrapper.get(`${API_BASE_URL}/visitor/getbyid?id=${visitorId}`); // Use the base URL
}

async function visitorAddEdit(visitor) {
    return await fetchWrapper.post(`${API_BASE_URL}/visitor/addedit`, visitor); // Use the base URL
}

async function deleteVisitor(visitorId) {
    return await fetchWrapper.delete(`${API_BASE_URL}/visitor/delete?id=${visitorId}}`);
}