import { fetchWrapper } from '../helpers';

const API_BASE_URL = process.env.REACT_APP_API_URL

export const usersService = {
    getAllUsers,
    getUserById,
    userAddEdit,
    deleteUser
};

async function getAllUsers(currentPage, pageSize, OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/users/list?page=${currentPage}&pageSize=${pageSize}&OrgId=${OrgId}`);
}

async function getUserById(userId) {
    return await fetchWrapper.get(`${API_BASE_URL}/users/getbyid?id=${userId}`); // Use the base URL
}

async function deleteUser(userId) {
    return await fetchWrapper.delete(`${API_BASE_URL}/users/delete?id=${userId}`); // Use the base URL
}

async function userAddEdit(users) {
    return await fetchWrapper.post(`${API_BASE_URL}/users/addedit`, users); // Use the base URL
}
