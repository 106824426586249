import React, { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { IInvitationDetails, invitationDetailsInitValues as initialValues } from './InvitationsModel'
import * as Yup from 'yup'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { lookupService } from '../../../../../services/lookup.service'
import { invitationService } from '../../../../../services/invitation.service'
import { visitorsService } from '../../../../../services/visitors.service'
import { useAuth } from '../../../auth'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { VisitorTypes } from './VisitorModel'

const invitationDetailsSchema = Yup.object().shape({
    Purpose: Yup.string().required('Purpose is required'),
    Email: Yup.string().email('Invalid email address').required('Email is required')
        .test('email-exists', 'Email already exists', async function (value) {
            if (value) {
                try {
                    const response = await invitationService.emailExist(value);

                    return !response.exists;
                } catch (error) {
                    console.error('Error checking email existence:', error);
                    return true;
                }
            }
            return true;
        }),
    HostID: Yup.string().required('Host is required'),
    VisitorTypeID: Yup.string().required('VisitorType is required'),
    FirstName: Yup.string().required('FirstName is required'),
    LastName: Yup.string().required('LastName is required')
})

interface Props {
    invitationData?: IInvitationDetails; // Visitor data for editing
    invitationId?: number;
}

const InvitationAdd: FC<Props> = ({ invitationData }) => {

    const [loading, setLoading] = useState(false)
    const { invitationId } = useParams<{ invitationId: string }>();
    const [formData, setFormData] = useState<IInvitationDetails>(initialValues);

    const [visitors, setVisitors] = useState<any[]>([]);
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate();
    const [visitorsTypes, setVisitorsTypes] = useState<VisitorTypes[]>([]);
    const [emailInputMode, setEmailInputMode] = useState(false); // State to control email input mode


    useEffect(() => {
        const storedOrganization = localStorage.getItem('selectedOrganization');
        const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
        visitorsService.getAllVisitors(1, 10, OrgId).then((data: any) => {
            setVisitors(data.visitors);
        }).catch(error => {
            console.error('Error fetching visitors:', error);
        });
    }, []);

    useEffect(() => {
        const storedOrganization = localStorage.getItem('selectedOrganization');
        const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
        lookupService.getAllVisitorsTypes(OrgId).then((data: any) => {
            setVisitorsTypes(data.visitortypes);
        }).catch(error => {
            console.error('Error fetching watchlist:', error);
        });
    }, []);

    useEffect(() => {
        const fetchInvitationDetails = async () => {
            setLoading(true);
            try {
                const response = await invitationService.getInvitationById(invitationId);
                formik.setFieldValue('Purpose', response.Purpose);
                formik.setFieldValue('Note', response.Note);
                formik.setFieldValue('Email', response.Email);
                formik.setFieldValue('HostID', response.HostID);
                formik.setFieldValue('VisitorID', response.VisitorID);
                formik.setFieldValue('FirstName', response.FirstName);
                formik.setFieldValue('LastName', response.LastName);
                formik.setFieldValue('VisitorTypeID', response.VisitorTypeID);
                // setFormData(response.data);
                setLoading(false);
                // Update the form values with the retrieved data
                // setVisitorData(response.data);
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching invitation details:', error);
                setLoading(false);
            }
        };

        if (invitationId) {
            fetchInvitationDetails();
        }
    }, [invitationId]);


    const handleEmailChange = (option: any) => {
        const selectedVisitor = visitors.find(visitor => visitor.Email === option.label);
        if (selectedVisitor) {
            formik.setFieldValue('Email', selectedVisitor.Email);
            formik.setFieldValue('FirstName', selectedVisitor.FirstName);
            formik.setFieldValue('LastName', selectedVisitor.LastName);
            formik.setFieldValue('VisitorTypeID', selectedVisitor.VisitorTypeID);
            formik.setFieldValue('VisitorID', selectedVisitor.ID);
        } else {
            formik.setFieldValue('Email', option.label);
            formik.setFieldValue('FirstName', '');
            formik.setFieldValue('LastName', '');
            formik.setFieldValue('VisitorTypeID', '');
            formik.setFieldValue('VisitorID', null);
        }
    };

    const options = visitors
        .filter(visitor => visitor.visitorTypeName === 'Employee')
        .map(visitor => ({
            value: visitor.ID,
            label: `${visitor.LastName}, ${visitor.FirstName}`, // Displaying last name first followed by a comma and then first name
        }));
    const optionsEmails = visitors
        .filter(visitor => visitor.visitorTypeName === 'Employee')
        .map(visitor => ({
            value: visitor.ID,
            label: `${visitor.Email}`, // Displaying last name first followed by a comma and then first name
        }));

    const initialValuesForForm = invitationData ? invitationData : formData;

    const formik = useFormik<IInvitationDetails>({
        initialValues: initialValuesForForm || formData, // Use formData as initialValues
        validationSchema: invitationDetailsSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                if (invitationId) {
                    values.ID = Number(invitationId);
                }
                const storedOrganization = localStorage.getItem('selectedOrganization');
                const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
                values.OrgId = OrgId;
                const response = await invitationService.invitationAddEdit(values);
                console.log('Visitor added/edited successfully:', response);
                formik.resetForm();
                setLoading(false);
                navigate('/apps/settings/notifications');
            } catch (error) {
                console.error('Error adding/editing visitor:', error);
                setLoading(false);
            }
            setSubmitting(false);
        },
    });

    const toggleEmailInputMode = () => {
        setEmailInputMode(!emailInputMode);
        if (!emailInputMode) {
            formik.setFieldValue('Email', '');
            formik.setFieldValue('FirstName', '');
            formik.setFieldValue('LastName', '');
            formik.setFieldValue('VisitorTypeID', '');
            formik.setFieldValue('VisitorID', null);
        }
    };

    return (
        <>
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                    <form onSubmit={formik.handleSubmit} noValidate className='form' style={{ backgroundColor: 'white' }}>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                                <div className='col-lg-8 fv-row'>
                                    <CreatableSelect
                                        className='form-control form-control-lg form-control-solid'
                                        name='Email'
                                        options={optionsEmails}
                                        value={optionsEmails.find(option => option.value === formik.values.Email)}
                                        onChange={handleEmailChange}
                                        onBlur={formik.handleBlur}
                                        isClearable
                                        noOptionsMessage={() => 'No options'}
                                    />
                                    {formik.touched.Email && formik.errors.Email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Email}</div>
                                        </div>
                                    )}

                                    {/* <button type='button' onClick={toggleEmailInputMode} className='btn btn-link'>
                                        {emailInputMode ? 'Select from list' : 'Enter manually'}
                                    </button> */}
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
                                <div className='col-lg-8'>
                                    <div className='row'>
                                        <div className='col-lg-6 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='First name'
                                                {...formik.getFieldProps('FirstName')}
                                            />
                                            {formik.touched.FirstName && formik.errors.FirstName && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.FirstName}</div>
                                                </div>
                                            )}
                                        </div>

                                        <div className='col-lg-6 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Last name'
                                                {...formik.getFieldProps('LastName')}
                                            />
                                            {formik.touched.LastName && formik.errors.LastName && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.LastName}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Visitor Type</label>
                                <div className='col-lg-8 fv-row'>
                                    <select className='form-select form-select-solid form-select-lg' {...formik.getFieldProps('VisitorTypeID')}>
                                        <option value=''>Select a Visitor Type...</option>
                                        {visitorsTypes.map((visitorType) => (
                                            <option key={visitorType.ID} value={visitorType.ID}>
                                                {visitorType.Name}
                                            </option>
                                        ))}
                                    </select>
                                    {formik.touched.VisitorTypeID && formik.errors.VisitorTypeID && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.VisitorTypeID}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Purpose</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        {...formik.getFieldProps('Purpose')}
                                    />
                                    {formik.touched.Purpose && formik.errors.Purpose && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Purpose}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Host</label>
                                <div className='col-lg-8 fv-row'>
                                    <Select
                                        className='form-control form-control-lg form-control-solid'
                                        name='HostID'
                                        options={options}
                                        value={options.find(option => option.value === formik.values.HostID)}
                                        onChange={option => formik.setFieldValue('HostID', option ? option.value : '')}
                                        onBlur={formik.handleBlur}
                                        filterOption={({ label }, searchText) => label.toLowerCase().startsWith(searchText.toLowerCase())} // Custom filter to search by label from left to right
                                    />
                                    {formik.touched.HostID && formik.errors.HostID && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.HostID}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className=''>Note</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <textarea
                                        id='Note'
                                        className='form-control form-control-solid mb-8'
                                        rows={4}
                                        {...formik.getFieldProps('Note')}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={loading || formik.isSubmitting}>
                                {!loading && 'Save Changes'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { InvitationAdd }