import React, { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { IVisitorActivityDetails, visitorActivityDetailsInitValues as initialValues, VisitorActivity } from './Models/VisitorActivity'
import * as Yup from 'yup'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { lookupService } from '../../../services/lookup.service'
import { visitorActivityService } from '../../../services/visitoractivity.service'
import { VisitorTypes } from '../../modules/apps/invites/components/VisitorModel'
import { useAuth } from '../../modules/auth'

const visitorDetailsSchema = Yup.object().shape({
    FirstName: Yup.string().required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    Email: Yup.string().email('Invalid email address').required('Email is required'),
    Phone: Yup.string().required('phone is required'),
    VisitorTypeID: Yup.string().required('VisitorType is required')
})

interface Props {
    visitorData?: IVisitorActivityDetails; // Visitor data for editing
    visitorActivityId?: number;
}

const VisitorActivityEdit: FC<Props> = ({ visitorData }) => {

    const [loading, setLoading] = useState(false)
    const { visitorActivityId } = useParams<{ visitorActivityId: string }>();
    const [formData, setFormData] = useState<IVisitorActivityDetails>(initialValues);
    const [visitorTypes, setVisitorTypes] = useState<VisitorTypes[]>([]);
    const { currentUser, logout } = useAuth()

    useEffect(() => {
        const storedOrganization = localStorage.getItem('selectedOrganization');
        const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
        lookupService.getAllVisitorsTypes(OrgId).then((data: any) => {
            setVisitorTypes(data.visitortypes);
        }).catch(error => {
            console.error('Error fetching visitortypes:', error);
        });
    }, []);

    useEffect(() => {
        const fetchVisitorDetails = async () => {
            setLoading(true);
            try {
                const response = await visitorActivityService.getVisitorActivityById(visitorActivityId);
                formik.setFieldValue('FirstName', response.FirstName);
                formik.setFieldValue('LastName', response.LastName);
                formik.setFieldValue('Email', response.Email);
                formik.setFieldValue('Phone', response.Phone);
                formik.setFieldValue('VisitorTypeID', response.VisitorTypeID);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching visitorActivity details:', error);
                setLoading(false);
            }
        };

        if (visitorActivityId) {
            fetchVisitorDetails();
        }
    }, [visitorActivityId]);

    const initialValuesForForm = visitorData ? visitorData : formData;

    const formik = useFormik<IVisitorActivityDetails>({
        initialValues: initialValuesForForm, // Use formData as initialValues
        validationSchema: visitorDetailsSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {

                if (visitorActivityId) {
                    values.ID = Number(visitorActivityId);
                }
                const response = await visitorActivityService.visitorActivityAddEdit(values);
                console.log('visitorActivity edited successfully:', response);
                formik.resetForm();
                setLoading(false);
                window.history.back();
            } catch (error) {
                console.error('Error editing visitorActivity:', error);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                    <form onSubmit={formik.handleSubmit} noValidate className='form' style={{ backgroundColor: 'white' }}>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

                                <div className='col-lg-8'>
                                    <div className='row'>
                                        <div className='col-lg-6 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='First name'
                                                {...formik.getFieldProps('FirstName')}
                                            />
                                            {formik.touched.FirstName && formik.errors.FirstName && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.FirstName}</div>
                                                </div>
                                            )}
                                        </div>

                                        <div className='col-lg-6 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Last name'
                                                {...formik.getFieldProps('LastName')}
                                            />
                                            {formik.touched.LastName && formik.errors.LastName && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.LastName}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        {...formik.getFieldProps('Email')}
                                    />
                                    {formik.touched.Email && formik.errors.Email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Email}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Phone</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        {...formik.getFieldProps('Phone')}
                                    />
                                    {formik.touched.Phone && formik.errors.Phone && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Phone}</div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Visitor Type</label>
                                <div className='col-lg-8 fv-row'>
                                    <select className='form-select form-select-solid form-select-lg' {...formik.getFieldProps('VisitorTypeID')}>
                                        <option value="">Select Watchlist Type</option>
                                        {visitorTypes.map((visitorType) => (
                                            <option key={visitorType.ID} value={visitorType.ID}>
                                                {visitorType.Name}
                                            </option>
                                        ))}
                                    </select>
                                    {formik.touched.VisitorTypeID && formik.errors.VisitorTypeID && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.VisitorTypeID}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                {!loading && 'Save Changes'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { VisitorActivityEdit }