
export interface LocationsDto {
    ID: number;
    Name: string;
    CompanyName: string;
    OrganizationID: string;
    IsActive: boolean;
  }

  
  export interface ILocationsDetails{
    ID: number;
    AccountID: number;
    Name: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    ZipCode: string;
    Country: string;
    ContactName: string;
    ContactPhone: string;
    ContactEmail: string;
    Logo: string;
    OrganizationID: string;
    IsActive: boolean;
    TimeZone : string;
  }

  export const locationsDetailsInitValues: ILocationsDetails = {
    ID: 0,
    AccountID : 0,
    Name : '',
    Address1 : '',
    Address2 : '',
    City : '',
    State : '',
    ZipCode : '',
    Country : '',
    ContactName : '',
    ContactPhone : '',
    ContactEmail : '',
    Logo : '',
    OrganizationID : '',
    IsActive:false,
    TimeZone : ''
  }