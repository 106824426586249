
export interface ClientsDto {
    ID: number;
    LocationCount: number;
    CompanyName: string;
    LocationNames: string;
    Logo: string;
    IsActive: boolean;
  }

  
  export interface IClientsDetails{
    ID: number;
    CompanyName: string;
    Logo: string;
    IsActive: boolean;
    File?: File; 
  }

  export const clientsDetailsInitValues: IClientsDetails = {
    ID: 0,
    CompanyName : '',
    Logo : '',
    IsActive:false
  }