
export interface VisitorActivity {
    ID: number;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    VisitorTypeName: string;
    CheckIn: string;
    VisitorTypeID: string;
  }

  
  export interface IVisitorActivityDetails{
    ID: number;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    VisitorTypeName: string;
    VisitorTypeID: string;
  }

  export const visitorActivityDetailsInitValues: IVisitorActivityDetails = {
    ID: 0,
    VisitorTypeID: '',
    FirstName : '',
    LastName : '',
    Email : '',
    Phone:'',
    VisitorTypeName : ''
  }