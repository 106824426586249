import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../helpers';
import { visitorsService } from '../../../../services/visitors.service';
import { IVisitorDetails, Visitor, VisitorTypes } from '../../../../app/modules/apps/invites/components/VisitorModel';
import { Button, Modal } from 'react-bootstrap';
import { useAuth } from '../../../../app/modules/auth';
import { lookupService } from '../../../../services/lookup.service';
import { DeleteModal } from '../../modals/delete-modal/DeleteModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileUploadInput from '../../../../app/modules/apps/invites/components/FileUploadVisitor';

type Props = {
  className: string;
};

const TablesVisitors: React.FC<Props> = ({ className }) => {
  const [visitors, setVisitors] = useState<Visitor[]>([]);
  const [visitorTypes, setVisitorTypes] = useState<VisitorTypes[]>([]);
  const [selectedVisitorType, setSelectedVisitorType] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  // const pageSize = 2; // Number of entries per page
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(false); // State to track select all checkbox
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<string>('FirstName');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [visitorToDeleteId, setVisitorToDeleteId] = useState<number | null>(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);

  const [pageSize, setPageSize] = useState<number>(15); // Number of entries per page
  const { currentUser, logout } = useAuth()
  const [loading, setLoading] = useState<boolean>(true);
  const [visitorsTypes, setVisitorsTypes] = useState<VisitorTypes[]>([]);

  // Function to toggle individual checkbox selection
  const toggleCheckbox = (visitorId: number) => {
    if (selectedCheckboxes.includes(visitorId)) {
      setSelectedCheckboxes(prevState => prevState.filter(id => id !== visitorId));
    } else {
      setSelectedCheckboxes(prevState => [...prevState, visitorId]);
    }
  };

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(prevSortDirection => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };


  useEffect(() => {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    fetchVisitors(storedOrganization);
  }, [currentPage, selectedVisitorType, pageSize]); // Fetch visitors whenever the page changes or the visitor type selection changes

  const fetchVisitors = async (storedOrganization: string | null) => {
    try {
      setLoading(true);
      setVisitors([]);
      const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
      const data = await visitorsService.getAllVisitors(currentPage, pageSize, OrgId);
      setVisitors(data.visitors);
      setVisitorTypes(data.visitorTypes);
      setTotalEntries(data.totalEntries);
    } catch (error) {
      console.error('Error fetching visitors:', error);
    } finally {
      setLoading(false); // Set loading back to false after fetching data
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Function to handle "Select All" checkbox
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      const allIds = visitors.map(visitor => visitor.ID);
      setSelectedCheckboxes(allIds);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  // Function to check if a checkbox is selected
  const isCheckboxSelected = (visitorId: number) => {
    return selectedCheckboxes.includes(visitorId);
  };

  const toggleSelectAll = () => {
    setSelectAll(prevState => !prevState);
  };

  const totalPages = Math.ceil(totalEntries / pageSize);
  const paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
        <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
      </li>
    );
  }

  const openDeleteModal = (visitorId: number) => {
    setVisitorToDeleteId(visitorId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      if (!visitorToDeleteId) return;
      await visitorsService.deleteVisitor(visitorToDeleteId);
      // If successful, remove the deleted visitor from the state
      setVisitors(prevVisitors => prevVisitors.filter(visitor => visitor.ID !== visitorToDeleteId));
      // Close the modal
      setShowDeleteModal(false);
      console.log('Visitor deleted successfully');
    } catch (error) {
      console.error('Error deleting visitor:', error);
    }
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing page size
  };


  const filterVisitors = () => {
    let filteredVisitors = visitors;

    // If search input is provided, filter visitors based on first name or last name
    if (searchInput) {
      filteredVisitors = visitors.filter((visitor) => {
        const fullName = `${visitor.FirstName} ${visitor.LastName}`.toLowerCase();
        return fullName.includes(searchInput.toLowerCase());
      });
    }

    if (selectedVisitorType) {
      filteredVisitors = filteredVisitors.filter(visitor => Number(visitor.VisitorTypeID) === Number(selectedVisitorType));
    }

    filteredVisitors.sort((a: any, b: any) => {
      const columnA = a[sortColumn].toLowerCase();
      const columnB = b[sortColumn].toLowerCase();
      if (columnA < columnB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (columnA > columnB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return filteredVisitors;
  };

  useEffect(() => {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
    lookupService.getAllVisitorsTypes(OrgId).then((data: any) => {
      setVisitorsTypes(data.visitortypes);
    }).catch(error => {
      console.error('Error fetching watchlist:', error);
    });
  }, []);


  // Function to convert table data into CSV format
  const convertToCSV = () => {
    if (visitors.length === 0) return;

    // Define keys to be excluded
    const excludeKeys = ['ID', 'LocationID', 'VisitorTypeID'];

    // Get all keys from the first visitor object and filter out the unwanted keys
    const allKeys = Object.keys(visitors[0]);
    const headers = allKeys.filter(key => !excludeKeys.includes(key));

    const csvRows = [];
    csvRows.push(headers.join(','));

    visitors.forEach((visitor: any) => {
      const values = headers.map(header => visitor[header] || '');
      csvRows.push(values.join(','));
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', 'visitors.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const handleFileUpload = (e: any) => {
  // }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
     const file = e.target.files?.[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target) return;
  
      const result = event.target.result as string;
      // Parse the CSV data
      const parsedData = parseCSV(result);
      try {
         const storedOrganization = localStorage.getItem('selectedOrganization');
        const OrgId = storedOrganization || currentUser?.OrganizationID;
  
        const promises = parsedData.map(async (visitor, index) => {
          visitor.OrgId = OrgId;
          const VTId = visitorsTypes.find(vt => vt.Name === visitor.VisitorTypeID)?.ID;
          visitor.VisitorTypeID = VTId ? VTId.toString() : '';  
  
          try {
            setLoading(true);
           const visitorData = await visitorsService.visitorAddEdit(visitor);
           if(visitorData.success){
            toast.success('Visitors updated successfully!');
           }
           else{
            toast.error(`Error updating visitor with email ${visitor.Email} at row ${index + 1}. Please try again.`);
           }

          } catch (error) {
            console.error(`Error adding/editing visitor at row ${index + 1} with email ${visitor.Email}:`, error);
          }
        });
  
        await Promise.all(promises);
  
        fetchVisitors(storedOrganization);
        setLoading(false);
        console.log('Visitors added/edited successfully');
      } catch (error) {
        console.error('Error processing visitors:', error);
        toast.error('Error processing visitors. Please try again.');
      } 
    }; 
    reader.readAsText(file);
  };
  
  

  // Function to parse CSV data
  const parseCSV = (csvData: string): IVisitorDetails[] => {
    // Split the CSV data by lines
    const lines = csvData.split('\n');
    const parsedVisitors: IVisitorDetails[] = [];
    // Start iteration from the second line to skip headers
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      if (line.trim() !== '') { // Skip empty lines
        const [
          firstName,
          lastName,
          email,
          visitorType,
          phone,
          title,
          department,
          badgeID,
          secondaryID
        ] = line.split(',');
        parsedVisitors.push({
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          VisitorTypeID: visitorType,
          Phone: phone,
          ID: 0, // Assuming default ID as 0
          Title: title || '', // Default to empty string if undefined
          BadgeID: badgeID || '', // Default to empty string if undefined
          Photo: '', // Default to empty string, as Photo is not in the CSV
          SecondaryID: secondaryID || '' // Default to empty string if undefined
        });
      }
    }

    return parsedVisitors;
  };


  const handleStorageChange = () => {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    setVisitors([]);
    fetchVisitors(storedOrganization);
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  


  return (
    <>
      <ToastContainer />
      <div className={`card ${className}`}>
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          onDelete={handleDelete}
          text='visitor'
        />
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-3 position-absolute ms-3'
            />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm ps-9 w-500px'
              placeholder='Search'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)} // Update search input value
            />
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm m-5'
              defaultValue=''
              onChange={(e) => setSelectedVisitorType(e.target.value)}
            >
              <option value=''>ALL</option>
              {visitorTypes.map((visitorType) => (
                <option key={visitorType.ID} value={visitorType.ID}>
                  {visitorType.Name}
                </option>
              ))}
            </select>

          </div>

          {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Visitors</span>
        </h3> */}
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            {/* Export CSV button */}
            <div className='card-toolbar'>
              <button className='btn btn-sm btn-light-primary' onClick={convertToCSV}>
                <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Export CSV
              </button>
            </div>
            {/* <a
            href='#'
            className='btn btn-sm btn-light-primary m-1'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Upload CSV
          </a> */}
           <FileUploadInput onChange={handleFileUpload} />
            {/* <div className='form-group'>
              <label htmlFor='fileInput' className='btn btn-sm btn-light-primary m-1'>
                <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Upload CSV
              </label>
              <input
                id='fileInput'
                type='file'
                accept='.csv'
                className='form-control d-none'
                onChange={handleFileUpload}
              />
            </div> */}

            <a
              href='/visitors/add'
              className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Add Visitor
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* Display loader if loading */}
              {loading && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    <img src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" alt="Loading..." />
                  </td>
                </tr>
              )}
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                        // checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </th>
                  {/* <th className='min-w-150px'>Authors</th> */}
                  <th className='min-w-140px' onClick={() => handleSort('FirstName')}>
                    First Name
                    {sortColumn === 'FirstName' && (
                      <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                        {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                      </span>
                    )}
                  </th>

                  <th className='min-w-140px' onClick={() => handleSort('LastName')}>
                    Last Name
                    {sortColumn === 'LastName' && (
                      <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                        {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                      </span>
                    )}
                  </th>
                  <th className='min-w-140px' onClick={() => handleSort('Email')}>
                    Email
                    {sortColumn === 'Email' && (
                      <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                        {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                      </span>
                    )}
                  </th>
                  <th className='min-w-140px'>Phone</th>
                  <th className='min-w-140px'>BadgeID</th>
                  {/* <th className='min-w-140px'>Visitor Type</th> */}
                  <th className='min-w-140px' onClick={() => handleSort('visitorTypeName')}>
                    Visitor Type
                    {sortColumn === 'visitorTypeName' && (
                      <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                        {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                      </span>
                    )}
                  </th>
                  {/* <th className='min-w-120px'>Last Action</th> */}
                  <th className='min-w-140px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filterVisitors().map((visitor: Visitor) => (
                  <tr key={visitor.ID}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value={visitor.ID.toString()}
                          // checked={selectAll}
                          checked={isCheckboxSelected(visitor.ID)}
                          onChange={() => toggleCheckbox(visitor.ID)}
                        />
                      </div>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {visitor.FirstName}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {visitor.LastName}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {visitor.Email}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {visitor.Phone}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {visitor.BadgeID}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {visitor.visitorTypeName}
                      </span>
                    </td>
                    {/* <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>Checked-in at Frontdesk on</span>
                      </div>
                      <span className='text-muted me-2 fs-7 fw-semibold'>28 Oct 2022 12:45 PM</span>
                    </div>
                  </td> */}
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {/* <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen019.svg'
                          className='svg-icon-3'
                        />
                      </a> */}
                        <a
                          href={`/visitors/edit/${visitor.ID}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        </a>
                        <a
                          href='javascript:void(0)'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={() => openDeleteModal(visitor.ID)} // Call handleDelete function on click
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {/* Pagination */}
        <div className="d-flex flex-stack flex-wrap pt-10 justify-content-lg-start m-5">
          <div className="fs-6 fw-bold text-gray-700 me-3">view records per page </div>
          <div className="me-3">
            <select
              className="form-select form-select-sm"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="60">60</option>
              <option value="90">90</option>
            </select>
          </div>

          <ul className="pagination p-5">
            {/* Previous button */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
            </li>
            {/* Page numbers */}
            {paginationItems}
            {/* Next button */}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export { TablesVisitors };
