import { fetchWrapper } from '../helpers';
const API_BASE_URL = process.env.REACT_APP_API_URL

export const lookupService = {
    getAllWatchlistTypes,
    getAllVisitorsTypes,
    getAllDocumentTypes,
    getAllVisitors,
    getWelcomeMessageDta,
    getAccessPointDta,
    getAllAccounts,
    getAllRoles
};


async function getAllWatchlistTypes() {
    return await fetchWrapper.get(`${API_BASE_URL}/lookup/getwatchlisttype`);
}

async function getAllVisitorsTypes(OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/lookup/getvisitorstypes?OrgId=${OrgId}`);
}
async function getAllVisitors() {
    return await fetchWrapper.get(`${API_BASE_URL}/lookup/getvisitors`);
}

async function getAllDocumentTypes() {
    return await fetchWrapper.get(`${API_BASE_URL}/lookup/getdocumenttype`);
}

// async function getSuccessMessageDta(OrgId) {
//     return await fetchWrapper.get(`${API_BASE_URL}/success/getbyid?OrgId=${OrgId}`);
// }

async function getWelcomeMessageDta(OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/welcome/getbyid?OrgId=${OrgId}`);
}

async function getAccessPointDta(OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/lookup/getaccesspoints?OrgId=${OrgId}`);
}

async function getAllAccounts() {
    return await fetchWrapper.get(`${API_BASE_URL}/lookup/getaccounts`);
}
async function getAllRoles() {
    return await fetchWrapper.get(`${API_BASE_URL}/lookup/getroles`);
}
