/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Key, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  StatisticsWidget5,
  StatisticsWidget1
} from '../../../_metronic/partials/widgets'
import { ChartsDashboardTotalVisits } from '../../../_metronic/partials/widgets/charts/ChartsDashboardTotalVisits'
import { ChartsDashboardVisitTimeOfDay } from '../../../_metronic/partials/widgets/charts/ChartsDashboardVisitTimeOfDay'
import { TablesLatestVisitorActivity } from '../../../_metronic/partials/widgets/tables/TablesLatestVisitorActivity'
import { VisitorTypes } from '../../modules/apps/invites/components/VisitorModel'
import { lookupService } from '../../../services/lookup.service'
import { StatisticsWidgetVisitors } from '../../../_metronic/partials/widgets/statistics/StatisticsWidgetVisitors'

import { dashboardService } from '../../../services/dashboard.service'
import { useAuth } from '../../modules/auth'
import React from 'react'


const DashboardPage: FC<{ visitorTypes: VisitorTypes[], totalInvites: any, visitCountsByMonth: any, visitorsTypeWithCount: any }> = ({ visitorTypes, totalInvites, visitCountsByMonth, visitorsTypeWithCount }) => {
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
  const totalVisitors = visitorsTypeWithCount?.reduce((total: number, item: { Total_Visitors: string }) => total + parseInt(item.Total_Visitors), 0);

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/svg/avatars/004-boy-1.svg'
            color='warning'
            iconColor='primary'
            title={totalVisitors}
            description='Total Number of Persons currently on PREMISE'
          />
        </div>

        {visitorsTypeWithCount?.map((item: { Total_Visitors: string; Name: string }, index: Key | null | undefined) => (
          <div className='col-xl-2' key={index}>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/finance/fin006.svg' // Assuming each item in visitorsTypeWithCount has an icon property
              color='dark'
              iconColor='primary'
              title={item.Total_Visitors} // Assuming each item in visitorsTypeWithCount has a count property
              description={item.Name} // Assuming each item in visitorsTypeWithCount has a name property
            />
          </div>
        ))}

        {/* <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color='dark'
            iconColor='primary'
            title='25'
            description='Visitors'
          />
        </div>

        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='dark'
            iconColor='primary'
            title='0'
            description='Interview Candidates'
          />
        </div>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color='dark'
            iconColor='primary'
            title='36'
            description='Deliveries'
          />
        </div>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color='dark'
            iconColor='primary'
            title='30'
            description='Contractor'
          />
        </div> */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <StatisticsWidgetVisitors
            className='card-xl-stretch mb-xl-8'
            image='abstract-4.svg'
            title='0'
            time='3:30PM - 4:20PM'
            description='Total number of Visits today'
            visitorTypes={visitorTypes} // Pass visitorTypes as a prop
          />
        </div>

        <div className='col-xl-6'>
          <StatisticsWidgetVisitors
            className='card-xl-stretch mb-xl-8'
            image='abstract-2.svg'
            title={totalInvites}
            time={formattedDate}
            description='Total Number of Invites expected today'
            visitorTypes={[]}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsDashboardTotalVisits className='card-xl-stretch mb-xl-8' visitCountsByMonth={visitCountsByMonth} />
        </div>
        <div className='col-xl-6'>
          <ChartsDashboardVisitTimeOfDay className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <TablesLatestVisitorActivity className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [visitorTypes, setVisitorTypes] = useState<VisitorTypes[]>([]);
  const [totalInvitesExpectedToday, setTotalInvitesExpectedToday] = useState<number>();
  const [visitCountsByMonth, setVisitCountsByMonth] = useState<any>();
  const [visitorsTypeWithCount, setVisitorsTypeWithCount] = useState<any>();
  const { currentUser, logout } = useAuth()

  // React.useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     const storedOrganization = localStorage.getItem('selectedOrganization');
  //     alert(storedOrganization);
  //   })
  // }, []);

  useEffect(() => {
    // Function to fetch dashboard info
    const fetchDashboardInfo = async (OrgId: string | null | undefined) => {
      try {
        const data = await dashboardService.getDashboardInfo(OrgId);
        setTotalInvitesExpectedToday(data.totalInvitesExpectedToday);
        setVisitCountsByMonth(data.visitCountsByMonth);
        setVisitorsTypeWithCount(data.visitorsTypeWithCount);
        console.log('Dashboard info:', data);
      } catch (error) {
        console.error('Error fetching dashboard info:', error);
      }
    };
  
    // Function to fetch visitor types
    const fetchVisitorTypes = async (OrgId: string | null | undefined) => {
      try {
        const data = await lookupService.getAllVisitorsTypes(OrgId);
        setVisitorTypes(data.visitortypes);
      } catch (error) {
        console.error('Error fetching visitortypes:', error);
      }
    };
  
    // Handle storage change event
    const handleStorageChange = (event: StorageEvent) => {
      const storedOrganization = localStorage.getItem('selectedOrganization');
        fetchDashboardInfo(storedOrganization);
        fetchVisitorTypes(storedOrganization);
    };
  
    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);
  
    const storedOrganization = localStorage.getItem('selectedOrganization');
    const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
  
    // Initial fetch of data
    fetchDashboardInfo(OrgId);
    fetchVisitorTypes(OrgId);
  
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [currentUser]);
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage visitorTypes={visitorTypes} totalInvites={totalInvitesExpectedToday} visitCountsByMonth={visitCountsByMonth} visitorsTypeWithCount={visitorsTypeWithCount} />

    </>
  )
}

export { DashboardWrapper }
