
import { fetchWrapper } from '../helpers';

const API_BASE_URL = process.env.REACT_APP_API_URL

export const clientsService = {
    getAllClients,
    getClientsById,
    clientsAddEdit,
    deleteClients,
    checkCompanyNameUnique
};


async function getAllClients(currentPage, pageSize) {
    return await fetchWrapper.get(`${API_BASE_URL}/clients/list?page=${currentPage}&pageSize=${pageSize}`);
}

async function getClientsById(clientId) {
    return await fetchWrapper.get(`${API_BASE_URL}/clients/getbyid?id=${clientId}`); // Use the base URL
}

async function clientsAddEdit(Clients) {
    return await fetchWrapper.post(`${API_BASE_URL}/clients/addedit`, Clients); // Use the base URL
}

async function deleteClients(clientId) {
    return await fetchWrapper.delete(`${API_BASE_URL}/clients/delete?id=${clientId}}`);
}

async function checkCompanyNameUnique(company, clientId) {
    return await fetchWrapper.get(`${API_BASE_URL}/clients/companyexist?company=${company}&&id=${clientId}`); // Use the base URL
}