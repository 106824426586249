/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils';
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider';
import { dashboardService } from '../../../../services/dashboard.service';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../../../../app/modules/auth';

type Props = {
  className: string;
};

const ChartsDashboardVisitTimeOfDay: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [chartData, setChartData] = useState<{ DayName: string; NumberOfVisitors: number }[]>([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    fetchData();
  }, [mode, currentUser]);

  const fetchData = async () => {
    try {
      const storedOrganization = localStorage.getItem('selectedOrganization');
      const OrgId = storedOrganization || currentUser?.OrganizationID;
      const data = await dashboardService.getLastWeekvisitschartdata(OrgId);
      const formattedData = data.visitsChartDatas.map((item: any) => ({
        DayName: format(parseISO(item.Date), 'EEEE'),
        NumberOfVisitors: item.NumberOfVisitors,
      }));
      setChartData(formattedData);
      refreshChart(formattedData);
    } catch (error) {
      console.error('Error fetching visit data:', error);
    }
  };

  const refreshChart = (data: { DayName: string; NumberOfVisitors: number }[]) => {
    if (!chartRef.current) return;

    const height = parseInt(getCSS(chartRef.current, 'height'));
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data));
    chart.render();

    return chart;
  };

  const handleStorageChange = () => {
    fetchData();
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // useEffect(() => {
  //   const chart = refreshChart(chartData);

  //   return () => {
  //     chart?.destroy();
  //   };
  // }, [chartRef, mode, chartData]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Visits by time of day</span>
          <span className='text-muted fw-semibold fs-7'>(x = day of week; y = visitor count)</span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          <a className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'>
            Export
          </a>
        </div>
      </div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_4_chart' style={{ height: '350px' }}></div>
      </div>
    </div>
  );
};

export { ChartsDashboardVisitTimeOfDay };

function getChartOptions(height: number, chartData: { DayName: string; NumberOfVisitors: number }[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500');
  const borderColor = getCSSVariableValue('--kt-gray-200');
  const baseColor = getCSSVariableValue('--kt-success');
  const baseLightColor = getCSSVariableValue('--kt-success-light');
  const secondaryColor = getCSSVariableValue('--kt-warning');
  const secondaryLightColor = getCSSVariableValue('--kt-warning-light');

  const categories = chartData.map(item => item.DayName);
  const data = chartData.map(item => item.NumberOfVisitors);

  return {
    series: [{ name: 'Number of Visitors', data }],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height,
      toolbar: { show: false },
    },
    xaxis: {
      categories,
      labels: { style: { colors: labelColor, fontSize: '12px' } },
      crosshairs: { stroke: { color: labelColor, width: 1, dashArray: 3 } },
    },
    yaxis: {
      labels: { style: { colors: labelColor, fontSize: '12px' } },
    },
    tooltip: {
      style: { fontSize: '12px' },
      y: { formatter: val => `${val} visitors` },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: { lines: { show: true } },
    },
    markers: {
      colors: [baseLightColor, secondaryLightColor],
      strokeColors: [baseLightColor, secondaryLightColor],
      strokeWidth: 3,
    },
  };
}
