import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../helpers'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { dashboardService } from '../../../../services/dashboard.service'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  className: string,
  visitCountsByMonth: any
}

const ChartsDashboardTotalVisits: React.FC<Props> = ({ className, visitCountsByMonth }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const [visitsChartDatas, setVisitsChartDatas] = useState<any>();
  const { currentUser } = useAuth()
  const chartInstance = useRef<ApexCharts | null>(null);

  useEffect(() => {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
    fetchTotalVisitsChartData(OrgId);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [mode])

  const fetchTotalVisitsChartData = (OrgId: any) => {
    dashboardService.gettotalvisitschartdata(OrgId).then((data: any) => {
      console.log('Dashboard info:', data);
      refreshChart(data.visitsChartDatas);
    }).catch(error => {
      console.error('Error fetching dashboard info:', error);
    });
  }

  const refreshChart = async (chartData: any) => {
    if (!chartRef.current || !chartData) {
      return
    }

    // Clear existing chart
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    // Calculate dynamic min and max for y-axis
    const yAxisMin = Math.min(...chartData.map((dataItem: any) => dataItem.NumberOfVisitors));
    const yAxisMax = Math.max(...chartData.map((dataItem: any) => dataItem.NumberOfVisitors));

    const chartOptions = await getChartOptions(height, chartData, yAxisMin, yAxisMax)
    const chart = new ApexCharts(chartRef.current, chartOptions)
    if (chart) {
      chart.render();
      chartInstance.current = chart; // Store the chart instance
    }

    return chart
  }

  const handleStorageChange = () => {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    fetchTotalVisitsChartData(storedOrganization);
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Total Visits</span>
          <span className='text-muted fw-semibold fs-7'> (x = visitor type; y = visitor count)</span>
        </h3>
        <div className='card-toolbar'>
          <a className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1' id='kt_charts_widget_6_sales_btn'>
            Export
          </a>
        </div>
      </div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
      </div>
    </div>
  )
}

export { ChartsDashboardTotalVisits }

async function getChartOptions(height: number, chartData: any, yAxisMin: number, yAxisMax: number): Promise<ApexOptions> {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')

  const categories = chartData.map((dataItem: any) => dataItem.Name)
  const data = chartData.map((dataItem: any) => dataItem.NumberOfVisitors)
 // Calculate dynamic tickAmount based on yAxis range
 const range = yAxisMax - yAxisMin;
 const tickAmount = range <= 10 ? range : Math.ceil(range / 5);

  return {
    series: [
      {
        name: 'Visitors Count',
        data: data,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
      tickAmount: tickAmount,  // Explicitly set the number of ticks
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val: number) {
          return val.toFixed(0); // Ensure labels are integers
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' visitors'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

