import getConfig from 'next/config';

import { userService } from '../services/user.service';
 


export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method)  {
    return  (url, body) => {
        const requestOptions = {
            method,
            // headers: {},
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // const { publicRuntimeConfig } = getConfig();
    // return auth header with jwt if user is logged in and request is to the api url
    const user = userService.userValue;
     const isLoggedIn = user?.token;
     
     const isApiUrl = url.startsWith('https://webservice.i-visits.com/api');
   
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
}

async function handleResponse(response) {
    const isJson = response.headers?.get('content-type')?.includes('application/json');
    const data = isJson ? await response.json() : null;
     // check for error response
    if (!response.ok) {
        if ([401, 403].includes(response.status) && userService.userValue) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            userService.logout();
        }

        // get error message from body or default to response status
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
}
