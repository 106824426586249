import { Button, Modal } from "react-bootstrap"

type DeleteModalProps = {
    show: boolean
    onHide: () => void
    onDelete: () => void,
    text: string
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ show, onHide, onDelete, text }) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Delete {text}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this {text}?</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Cancel</Button>
            <Button variant="danger" onClick={onDelete}>Delete</Button>
        </Modal.Footer>
    </Modal>
)