import React, { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { IClientsDetails, clientsDetailsInitValues as initialValues } from './ClientsDto'

import { clientsService } from '../../../services/clients.service'
const API_BASE_URL = process.env.REACT_APP_API_URL;

const clientDetailsSchema = (clientId?: string) =>
    Yup.object().shape({
        CompanyName: Yup.string()
            .required('Company Name is required')
            .test('unique-company-name', 'company name already exists', async (value) => {

                if (!value) return false;
                try {
                    const response = await clientsService.checkCompanyNameUnique(value, clientId);
                    return !response.exists;
                } catch (error) {
                    console.error('Error checking Company Name uniqueness:', error);
                    return false;
                }
            }),
        // Logo: Yup.string().required('Logo is required')
    });


interface Props {
    visitorData?: IClientsDetails; // Visitor data for editing
    clientId?: number;
}

const ClientAdd: FC<Props> = ({ visitorData }) => {

    const [loading, setLoading] = useState(false)
    const { clientId } = useParams<{ clientId: string }>();
    const [formDataSet, setFormData] = useState<IClientsDetails>({ ...initialValues, IsActive: !clientId });
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [imagePreview, setImagePreview] = useState<string | undefined>(undefined);

    const navigate = useNavigate(); // Use useNavigate hook

    useEffect(() => {
        const fetchVisitorDetails = async () => {
            setLoading(true);
            try {
                // Make API call to fetch visitor details using visitorId
                const response = await clientsService.getClientsById(clientId);
                formik.setFieldValue('CompanyName', response.CompanyName);
                formik.setFieldValue('IsActive', response.IsActive);
                // Set image preview if Logo exists
                if (response.Logo) {
                    formik.setFieldValue('Logo', response.Logo);
                    setImagePreview(response.Logo); // Assuming Logo already contains the full URL
                }

                // setFormData(response.data);
                setLoading(false);
                // Update the form values with the retrieved data
                // setVisitorData(response.data);
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching visitor details:', error);
                setLoading(false);
            }
        };

        if (clientId) {
            fetchVisitorDetails();
        }
    }, [clientId]);

    const initialValuesForForm = visitorData ? visitorData : formDataSet;

    const formik = useFormik<IClientsDetails>({
        initialValues: initialValuesForForm, // Use formData as initialValues
        validationSchema: clientDetailsSchema(clientId),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                if (clientId) {
                    values.ID = Number(clientId);
                }
                const formData = new FormData();
                formData.append('ID', String(values.ID));
                formData.append('CompanyName', values.CompanyName);
                formData.append('Logo', values.Logo);
                formData.append('IsActive', values.IsActive.toString());
                if (formDataSet.File) {
                    formData.append('file', formDataSet.File);
                }
                // const response = await clientsService.clientsAddEdit(formData);
                const response = await axios.post(`${API_BASE_URL}/clients/addedit`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log('Client added/edited successfully:', response);
                formik.resetForm();
                setLoading(false);
                navigate('/clients');
                window.location.reload();
            } catch (error) {
                console.error('Error adding/editing Client:', error);
                setLoading(false);
            }
        },
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setIsImageSelected(true);
            setFormData({ ...formDataSet, File: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <>
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                    <form onSubmit={formik.handleSubmit} noValidate className='form' style={{ backgroundColor: 'white' }}>
                        <div className='card-body border-top p-9'>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company Name</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        {...formik.getFieldProps('CompanyName')}
                                    />
                                    {formik.touched.CompanyName && formik.errors.CompanyName && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.CompanyName}</div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className=''>Logo</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        id='avatarUpload'
                                        type='file'
                                        accept='.png, .jpg, .jpeg'
                                        onChange={handleFileChange}
                                    />
                                    {imagePreview && (
                                        <img src={imagePreview} alt='Preview' style={{ maxWidth: '100%', marginTop: '10px', maxHeight: '100px' }} />
                                    )}
                                    {formik.touched.Logo && formik.errors.Logo && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Logo}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {clientId && (
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className=''>Status</span>
                                    </label>

                                    <div className='col-lg-8 d-flex align-items-center'>
                                        <div className='form-check form-switch fv-row'>
                                            <input
                                                className='form-check-input w-45px h-30px'
                                                type='checkbox'
                                                id='allowmarketing'
                                                checked={formik.values.IsActive} // Use formik values directly for checked attribute
                                                onChange={(e) => {
                                                    formik.setFieldValue('IsActive', e.target.checked); // Update IsActive directly using formik's setFieldValue
                                                }}
                                            />
                                            <label className='form-check-label'></label>
                                            {formik.touched.IsActive && formik.errors.IsActive && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.IsActive}</div>
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                </div>
                            )}
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                {!loading && 'Save Changes'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { ClientAdd }