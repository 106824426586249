import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import { WatchListAdd } from '../modules/watchlists/WatchListAdd'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { UsersAdd } from '../modules/users/UsersAdd'
import { ClientAdd } from '../modules/clients/ClientAdd'
import { ClientsPage } from '../modules/clients/ClientsPage'
import { LocationsAdd } from '../modules/locations/LocationsAdd'
import { LocationsPage } from '../modules/locations/LocationsPage'
import { VisitorActivityEdit } from '../pages/dashboard/VisitorActivityEdit'
// import { NotificationAdd } from '../modules/notifications/NotificationAdd'
import { VisitLog } from '../modules/apps/invites/components/VisitLog'
import { Visitors } from '../modules/apps/invites/components/Visitors'
import { VisitorAdd } from '../modules/apps/invites/components/VisitorAdd'
import { Invitations } from '../modules/apps/invites/components/Invitations'
import { InvitationAdd } from '../modules/apps/invites/components/InvitationAdd'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const InvitesPage = lazy(() => import('../modules/apps/invites/InvitesPage'))
  // const NotificationsPage = lazy(() => import('../modules/notifications/NotificationsPage'))
  // const WatchlistsPage = lazy(() => import('../modules/watchlists/WatchlistsPage'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const SettingsPage = lazy(() => import('../modules/apps/settings/SettingsPage'))
  const UsersPage = lazy(() => import('../modules/users/UsersPage'))

  // const generateBreadcrumbs = (...titles: string[]): PageLink[] => {
  //   const breadcrumbs: PageLink[] = titles.map((title, index) => ({
  //     title: title,
  //     path: index === 0 ? `/${title.toLowerCase()}` : '', // Assuming the path follows a pattern
  //     isSeparator: index !== titles.length - 1, // Add separator for all but the last breadcrumb
  //     isActive: false,
  //   }));
  //   return breadcrumbs;
  // };

  const generateBreadcrumbs = (...titles: string[]): PageLink[] => {
    const breadcrumbs: PageLink[] = titles.map((title, index) => {
      const formattedTitle = title.replace(/-/g, ' ');
      const path = index === 0 ? `/${title.toLowerCase()}` : '';
   
      if (title === "Visitor-Activity") {
        return {
          title: formattedTitle,
          path: '/dashboard',  
          isSeparator: false, 
          isActive: false,
        };
      }
  
      return {
        title: formattedTitle,
        path: path,
        isSeparator: index !== titles.length - 1, // Add separator for all but the last breadcrumb
        isActive: false,
      };
    });
  
    return breadcrumbs;
  };
  

  // Usage examples
  // const notificationBreadCrumbs = generateBreadcrumbs('Notifications');
  // const watchlistBreadCrumbs = generateBreadcrumbs('Watchlists');
  const userBreadCrumbs = generateBreadcrumbs('Users');
  const clientBreadCrumbs = generateBreadcrumbs('Clients');
  const locationBreadCrumbs = generateBreadcrumbs('Locations');
  const invitationBreadCrumbs = generateBreadcrumbs('Invitation');
  const visitorsBreadCrumbs = generateBreadcrumbs('Visitors');
  const visitorActivityBreadCrumbs = generateBreadcrumbs('Visitor-Activity');


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route path='visitorActivity/edit/:visitorActivityId'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={visitorActivityBreadCrumbs}>Visitor Activity Update</PageTitle>
              <VisitorActivityEdit />
            </SuspensedView>
          } />


        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* <Route path='invites' element={<InvitesWrapper />} /> */}
        {/* <Route path='notifications'
          element={
            <SuspensedView>
              <PageTitle>Notification</PageTitle>
              <NotificationsPage />
            </SuspensedView>
          } />
        <Route path='notifications/add'
          element={
            <SuspensedView>
              <PageTitle>Notifications Add</PageTitle>
              <PageTitle breadcrumbs={notificationBreadCrumbs}>Notifications Add</PageTitle>
              <NotificationAdd />
            </SuspensedView>
          } />

        <Route path='notifications/edit/:notificationId'
          element={
            <SuspensedView>
              <PageTitle>Notification Update</PageTitle>
              <PageTitle breadcrumbs={notificationBreadCrumbs}>Notifications Update</PageTitle>
              <NotificationAdd />
            </SuspensedView>
          } />

        <Route path='watchlists'
          element={
            <SuspensedView>
              <WatchlistsPage />
            </SuspensedView>
          } />
        <Route path='watchlists/add'
          element={
            <SuspensedView>
              <PageTitle>Watchlist Add</PageTitle>
              <PageTitle breadcrumbs={watchlistBreadCrumbs}>Watchlist Add</PageTitle>
              <WatchListAdd />
            </SuspensedView>
          } />

        <Route path='watchlists/edit/:watchlistId'
          element={
            <SuspensedView>
              <PageTitle>Watchlist Update</PageTitle>
              <PageTitle breadcrumbs={watchlistBreadCrumbs}>Watchlist Update</PageTitle>
              <WatchListAdd />
            </SuspensedView>
          } /> */}

        <Route path='reports'
          element={
            <SuspensedView>
              <PageTitle>Reports</PageTitle>
              <ReportsPage />
            </SuspensedView>
          } />

        <Route path='users'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          } />
        <Route path='users/add'
          element={
            <SuspensedView>
              <PageTitle>User Add</PageTitle>
              <PageTitle breadcrumbs={userBreadCrumbs}>User Add</PageTitle>
              <UsersAdd />
            </SuspensedView>
          } />

        <Route path='users/edit/:userId'
          element={
            <SuspensedView>
              <PageTitle>User Update</PageTitle>
              <PageTitle breadcrumbs={userBreadCrumbs}>User Update</PageTitle>
              <UsersAdd />
            </SuspensedView>
          } />

        <Route path='clients'
          element={
            <SuspensedView>
              <PageTitle>Clients</PageTitle>
              <ClientsPage />
            </SuspensedView>
          } />
        <Route path='clients/add'
          element={
            <SuspensedView>
              <PageTitle>Client Add</PageTitle>
              <PageTitle breadcrumbs={clientBreadCrumbs}>Client Add</PageTitle>
              <ClientAdd />
            </SuspensedView>
          } />

        <Route path='clients/edit/:clientId'
          element={
            <SuspensedView>
              <PageTitle>Client Update</PageTitle>
              <PageTitle breadcrumbs={clientBreadCrumbs}>Client Update</PageTitle>
              <ClientAdd />
            </SuspensedView>
          } />

        <Route path='locations'
          element={
            <SuspensedView>
              <PageTitle>Locations</PageTitle>
              <LocationsPage />
            </SuspensedView>
          } />
        <Route path='locations/add'
          element={
            <SuspensedView>
              <PageTitle>Location Add</PageTitle>
              <PageTitle breadcrumbs={locationBreadCrumbs}>Location Add</PageTitle>
              <LocationsAdd />
            </SuspensedView>
          } />

        <Route path='locations/edit/:branchId'
          element={
            <SuspensedView>
              <PageTitle>Location Update</PageTitle>
              <PageTitle breadcrumbs={locationBreadCrumbs}>Location Update</PageTitle>
              <LocationsAdd />
            </SuspensedView>
          } />


        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        {/* <Route
          path='apps/invites/*'
          element={
            <SuspensedView>
              <InvitesPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='visitlog'
          element={
            <>
              <PageTitle>Visit Log</PageTitle>
              <VisitLog />
            </>
          }
        />
        <Route
          path='visitors'
          element={
            <>
              <PageTitle>Visitors</PageTitle>
              <Visitors />
            </>
          }
        />
        <Route
          path='visitors/add'
          element={
            <>
              <PageTitle>Visitor Add</PageTitle>
              <PageTitle breadcrumbs={visitorsBreadCrumbs}>Visitor Add</PageTitle>
              <VisitorAdd />
            </>
          }
        />
        <Route
          path='visitors/edit/:visitorId'
          element={
            <>
              <PageTitle>Visitor Update</PageTitle>
              <PageTitle breadcrumbs={visitorsBreadCrumbs}>Visitor Update</PageTitle>
              <VisitorAdd />
            </>
          }
        />

        <Route
          path='invitation'
          element={
            <>
              <PageTitle>Invitation</PageTitle>
              <Invitations />
            </>
          }
        />
        <Route
          path='invitation/add'
          element={
            <>
              <PageTitle>Invitation Add</PageTitle>
              <PageTitle breadcrumbs={invitationBreadCrumbs}>Invitation Add</PageTitle>
              <InvitationAdd />
            </>
          }
        />
        <Route
          path='invitation/edit/:invitationId'
          element={
            <>
              <PageTitle>Invitation Update</PageTitle>
              <PageTitle breadcrumbs={invitationBreadCrumbs}>Invitation Update</PageTitle>
              <InvitationAdd />
            </>
          }
        />

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes >
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
