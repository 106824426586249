import { FC } from "react"
import { TablesClients } from "../../../_metronic/partials/widgets/tables/TablesClients"



const ClientsPage: FC = () => {
    return (
        <>
         <div className='row gy-5 gx-xl-8'>
             <div className='col-xl-12'>
                <TablesClients className='card-xxl-stretch mb-5 mb-xl-8' />
            </div>
        </div>
        </>
    )

}


export {ClientsPage}