
export interface Visitor {
  ID: number;
  LocationID: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Title: string;
  BadgeID: string;
  SecondaryID: string;
  VisitorTypeID: string;
  Photo: string;
  CreateDte: string,
  visitorTypeName: string,
  LocationName: string
}

export interface VisitorTypes {
  ID: number;
  LocationID: string;
  Name: string;
  Description: string;
  IsActive: string;
}

export interface IVisitorDetails {
  ID: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Title: string;
  BadgeID: string;
  SecondaryID: string;
  VisitorTypeID: string;
  Photo: string;
  OrgId?: string;
}

export const visitorDetailsInitValues: IVisitorDetails = {
  ID: 0,
  FirstName: '',
  LastName: '',
  Email: '',
  Phone: '',
  Title: '',
  BadgeID: '',
  SecondaryID : '',
  VisitorTypeID: '',
  Photo: '',
  OrgId: ''
}


export interface VisitorLog {
  ID: number;
  VisitorTypeID: number;
  DocumentType: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  visitorTypeName: string;
  LocationName: string;
  CheckIn: string;
  CheckOut: string;
}