/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      {/* <SidebarMenuItemWithSub
        to='/apps/invites'
        title={intl.formatMessage({ id: 'MENU.INVITES' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/art/invites.svg'
      >
        <SidebarMenuItem to='/apps/invites/log' title='Visit Log' hasBullet={true} />
        <SidebarMenuItem to='/apps/invites/visitors' title='Visitors' hasBullet={true} />
        <SidebarMenuItem to='/apps/invites/invitation' title='Invitation' hasBullet={true} />

      </SidebarMenuItemWithSub> */}

      <SidebarMenuItem
        to='/visitlog'
        title='Visit Log'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/art/invites.svg' />

      <SidebarMenuItem
        to='/visitors'
        title='Visitors'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/art/invites.svg' />

      <SidebarMenuItem
        to='/invitation'
        title='Invitation'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/art/invites.svg' />

      {/* <SidebarMenuItem
        to='/notifications'
        icon='/media/icons/duotune/art/notification-bell-svgrepo-com.svg'
        title={intl.formatMessage({ id: 'MENU.NOTIFICATIONS' })}
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItem
        to='/watchlists'
        icon='/media/icons/duotune/art/watchlist1.svg'
        title={intl.formatMessage({ id: 'MENU.WATCHLISTS' })}
        fontIcon='bi-app-indicator'
      /> */}

      <SidebarMenuItem
        to='/reports'
        icon='/media/icons/duotune/art/reports.svg'
        title={intl.formatMessage({ id: 'MENU.REPORTS' })}
        fontIcon='bi-app-indicator'
      />


      <SidebarMenuItem
        to='/users'
        icon='/media/icons/duotune/art/users.svg'
        title={intl.formatMessage({ id: 'MENU.USERS' })}
        fontIcon='bi-app-indicator'
      />

      {currentUser?.IsSuperAdmin ? (
        <>
          <SidebarMenuItem
            to='/clients'
            icon='/media/icons/duotune/art/users.svg'
            title={intl.formatMessage({ id: 'MENU.CLIENTS' })}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/locations'
            icon='/media/icons/duotune/art/users.svg'
            title={intl.formatMessage({ id: 'MENU.LOCATIONS' })}
            fontIcon='bi-app-indicator'
          />
        </>
      ) : null}


      <SidebarMenuItemWithSub
        to='/apps/settings'
        title={intl.formatMessage({ id: 'MENU.SETTINGS' })}
        icon='/media/icons/duotune/art/settings1.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/apps/settings/visitor-types' title='Visitor Types' hasBullet={true} />
        {currentUser?.IsSuperAdmin ? (
          <SidebarMenuItem to='/apps/settings/user-roles' title='Roles' hasBullet={true} />
        ) : null}
        <SidebarMenuItem to='/apps/settings/access-points' title='Access Points' hasBullet={true} />
        <SidebarMenuItem to='/apps/settings/document' title="Document" hasBullet={true} />
        <SidebarMenuItem to='/apps/settings/notifications' title={intl.formatMessage({ id: 'MENU.NOTIFICATIONS' })} hasBullet={true} />
        <SidebarMenuItem to='/apps/settings/watchlists' title={intl.formatMessage({ id: 'MENU.WATCHLISTS' })} hasBullet={true} />
        <SidebarMenuItem to='/apps/settings/welcome-messages' title="Welcome Message" hasBullet={true} />
        <SidebarMenuItem to='/apps/settings/success-messages' title="Success Message" hasBullet={true} />
        <SidebarMenuItem to='/apps/settings/processflow' title="Process Flow" hasBullet={true} />
        <SidebarMenuItem to='/apps/settings/visitorbadge' title="Visitor Badge" hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export { SidebarMenuMain }
