/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { locationsService } from '../../../../services/locations.service'
import { LocationsDto } from '../../../../app/modules/locations/LocationsDto'
import { Button, Modal } from 'react-bootstrap'
import { useAuth } from '../../../../app/modules/auth'
import { DeleteModal } from '../../modals/delete-modal/DeleteModal'

type Props = {
  className: string
}

const TablesLocations: React.FC<Props> = ({ className }) => {

  const [Branches, setBranches] = useState<LocationsDto[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  // const pageSize = 2; // Number of entries per page
  const [searchInput, setSearchInput] = useState<string>(''); // State to store search input value
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);

  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<string>('Name');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [locationToDeleteId, setLocationToDeleteId] = useState<number | null>(null);

  const [pageSize, setPageSize] = useState<number>(15); // Number of entries per page
  const { currentUser, logout } = useAuth()
  const [loading, setLoading] = useState<boolean>(true); // State variable to track loading status


  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(prevSortDirection => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };


  useEffect(() => {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    const storedAccount = localStorage.getItem('selectedAccount');
    fetchLocations(storedOrganization, storedAccount);
  }, [currentPage, pageSize]); // Fetch visitors whenever the page changes or the visitor type selection changes

  const fetchLocations = async (storedOrganization: string | null, storedAccount: string | null) => {
    try {
      setLoading(true);
      setBranches([]);
      const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
      const accountId = storedAccount ? storedAccount : currentUser?.AccountID;
      const data = await locationsService.getAllLocations(currentPage, pageSize, OrgId, accountId);
      setBranches(data.locations);
      setTotalEntries(data.totalEntries);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setLoading(false); // Set loading back to false after fetching data
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(totalEntries / pageSize);
  const paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
        <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
      </li>
    );
  }

  const openDeleteModal = (branchId: number) => {
    setLocationToDeleteId(branchId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      // Call your delete API endpoint here
      if (!locationToDeleteId) return;

      await locationsService.deleteLocations(locationToDeleteId);
      // If successful, remove the deleted access point from the state
      setBranches((prevBranches) => prevBranches.filter((ap) => ap.ID !== locationToDeleteId));
      setShowDeleteModal(false);
      console.log('Client deleted successfully');
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const filteredBranches = Branches.filter(branch =>
    branch.Name.toLowerCase().includes(searchInput.toLowerCase())
  );


  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      const allIds = Branches.map(Branche => Branche.ID);
      setSelectedCheckboxes(allIds);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const isCheckboxSelected = (notificationID: number) => {
    return selectedCheckboxes.includes(notificationID);
  };

  const toggleCheckbox = (notificationID: number) => {
    if (selectedCheckboxes.includes(notificationID)) {
      setSelectedCheckboxes(prevState => prevState.filter(id => id !== notificationID));
    } else {
      setSelectedCheckboxes(prevState => [...prevState, notificationID]);
    }
  };

  Branches.sort((a: any, b: any) => {
    const columnA = a[sortColumn].toLowerCase();
    const columnB = b[sortColumn].toLowerCase();
    if (columnA < columnB) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (columnA > columnB) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing page size
  };

  const handleStorageChange = () => {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    const storedAccount = localStorage.getItem('selectedAccount');
    fetchLocations(storedOrganization, storedAccount);
    setBranches([]);
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div className={`card ${className}`}>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
        text='Location'
      />
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className='d-flex align-items-center position-relative me-4'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-3 position-absolute ms-3'
          />
          <input
            type='text'
            id='kt_filter_search'
            className='form-control form-control-white form-control-sm ps-9 w-400px'
            placeholder='Search'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='/locations/add'
            className='btn btn-sm btn-light-primary'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Add Location
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Display loader if loading */}
            {loading && (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>
                  <img src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" alt="Loading..." />
                </td>
              </tr>
            )}
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAll}
                    />
                  </div>
                </th>
                {/* <th className='min-w-150px'>Authors</th> */}
                <th className='min-w-140px' onClick={() => handleSort('Name')}>
                  Name
                  {sortColumn === 'Name' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-140px' onClick={() => handleSort('OrganizationID')}>
                Organization ID
                  {sortColumn === 'OrganizationID' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-140px' onClick={() => handleSort('CompanyName')}>
                  Company Name
                  {sortColumn === 'CompanyName' && (
                    <span className={`ms-1 ${sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} cursor-pointer`}>
                      {sortDirection === 'asc' ? <>&#9650;</> : <>&#9660;</>}
                    </span>
                  )}
                </th>
                <th className='min-w-140px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredBranches.map((branch: LocationsDto) => (

                <tr key={branch.ID}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value={branch.ID.toString()}
                        // checked={selectAll}
                        checked={isCheckboxSelected(branch.ID)}
                        onChange={() => toggleCheckbox(branch.ID)}
                      />
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {branch.Name}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {branch.OrganizationID}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {branch.CompanyName}
                    </span>
                  </td>
                  <td>
                    <div className=''>
                      {branch.IsActive ? (
                        <span className="badge rounded-pill bg-success">Active</span>
                      ) : (
                        <span className="badge rounded-pill bg-danger">Inactive</span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>

                      <a
                        href={`/locations/edit/${branch.ID}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </a>
                      {/* <a
                        href='javascript:void(0)'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        onClick={() => openDeleteModal(branch.ID)} // Call handleDelete function on click
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a> */}

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Pagination */}
      <div className="d-flex flex-stack flex-wrap pt-10 justify-content-lg-start m-5">
        <div className="fs-6 fw-bold text-gray-700 me-3">view records per page </div>
        <div className="me-3">
          <select
            className="form-select form-select-sm"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
          </select>
        </div>

        <ul className="pagination p-5">
          {/* Previous button */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
          </li>
          {/* Page numbers */}
          {paginationItems}
          {/* Next button */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export { TablesLocations }
