import { fetchWrapper } from '../helpers';
const API_BASE_URL = process.env.REACT_APP_API_URL
  
export const dashboardService = {   
    getDashboardInfo, 
    getVisitorsFilter,
    gettotalvisitschartdata,
    getLastWeekvisitschartdata
};
 

async function getDashboardInfo(OrgId) { 
    return await fetchWrapper.get(`${API_BASE_URL}/dashboard/dashboardinfo?OrgId=${OrgId}`);
}
async function getVisitorsFilter(visitorTypeID, OrgId) { 
    return await fetchWrapper.get(`${API_BASE_URL}/dashboard/visitorsfilter?visitorTypeID=${visitorTypeID}&OrgId=${OrgId}`);
}
async function gettotalvisitschartdata(OrgId) { 
    return await fetchWrapper.get(`${API_BASE_URL}/dashboard/gettotalvisitschartdata?OrgId=${OrgId}`);
}
async function getLastWeekvisitschartdata(OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/dashboard/getlastweekvisitschartdata?OrgId=${OrgId}`);
}
 
 