import { useEffect, useState } from "react";
import { locationsService } from "../../../../../services/locations.service";
import { lookupService } from "../../../../../services/lookup.service";
import { useIntl } from "react-intl";
import { Organization } from "../../../../partials/widgets/tables/TablesUsersList";
import { useAuth } from "../../../../../app/modules/auth";

export function MenuInner() {
  const intl = useIntl();
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | number | undefined>('');
  const { currentUser } = useAuth();

  // Handler function to update selected organization
  const handleOrganizationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if(value !== ''){
      setSelectedOrganization(value);
      localStorage.setItem('selectedOrganization', value);
      window.dispatchEvent(new Event('storage'));

    }
  };

  // Handler function to update selected account
  const handleAccountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedAccount(value);
    localStorage.setItem('selectedAccount', value);
    if (window.location.pathname.includes('locations')) {
      window.dispatchEvent(new Event('storage'));
    }
  };


  useEffect(() => {
    // Fetch accounts data
    lookupService.getAllAccounts()
      .then((data: any) => {
        setAccounts(data.accounts);
        const storedAccount = localStorage.getItem('selectedAccount');
        const accountID = storedAccount ? storedAccount : currentUser?.AccountID;
        const filteredCom = data.accounts
          .filter((acc: any) => acc.ID === currentUser?.AccountID)
          .map((acc: any) => acc.CompanyName)[0];
        setCompanyName(filteredCom);
        setSelectedAccount(accountID);
      })
      .catch((error: any) => {
        console.error('Error fetching accounts:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch organizations data based on selected account
    if (selectedAccount) {
      locationsService.getAllOrganizations(selectedAccount)
        .then((data: any) => {
          if (!currentUser?.IsSuperAdmin) {
            const filteredOrg = data.organizations.filter((org: any) =>
              org.OrganizationID.toLowerCase().includes(currentUser?.OrganizationID.toLowerCase())
            );
            setOrganizations(filteredOrg);
          } else {
            setOrganizations(data.organizations);
          }
        })
        .catch((error: any) => {
          console.error('Error fetching organizations:', error);
        });
    }
  }, [selectedAccount, currentUser]);

  useEffect(() => {
    // Set the default selected organization based on currentUser.OrganizationID
    if (currentUser?.OrganizationID && organizations.length > 0) {
      const storedOrganization = localStorage.getItem('selectedOrganization');
      const orgId = storedOrganization ? storedOrganization : currentUser.OrganizationID;
      const defaultOrganization = organizations.find((org: { OrganizationID: any; }) => org.OrganizationID === orgId);
      if (defaultOrganization) {
        setSelectedOrganization(defaultOrganization.OrganizationID);
      }
    }
  }, [currentUser, organizations]);

  return (
    <div className=''>


      {currentUser?.IsSuperAdmin ? (
        <div className='menu-item me-lg-1 d-flex'>
          <span className='menu-title text-uppercase mt-7'>account</span>
          <select
            name='account'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm top-0 end-0 m-5 w-175px'
            value={selectedAccount}
            onChange={handleAccountChange}
          >
            {accounts.map((option) => (
              <option key={option.ID} value={option.ID}>
                {option.CompanyName}
              </option>
            ))}
          </select>

          <span className='menu-title text-uppercase mt-7'>location</span>
          <select
            name='organization'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm top-0 end-0 m-5 w-175px'
            value={selectedOrganization}
            onChange={handleOrganizationChange}
          ><option value="" selected>Select a location</option>
            {organizations.map((option) => (
              <option key={option.ID} value={option.OrganizationID}>
                {option.Name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className='mt-7'>
          <span className='menu-title text-uppercase'>Account : </span>
          <span>{companyName}</span>&nbsp;&nbsp;&nbsp;
          <span className='menu-title text-uppercase'>Location : </span>
          <span>{currentUser?.Name}</span>
        </div>
      )}
    </div>
  );
}
