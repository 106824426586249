import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { ILocationsDetails, locationsDetailsInitValues as initialValues } from './LocationsDto';

import { locationsService } from '../../../services/locations.service';
import { ClientsDto } from '../clients/ClientsDto';
import { clientsService } from '../../../services/clients.service';
import TimezoneSelect from 'react-timezone-select';

const clientDetailsSchema = (isEdit: boolean) => Yup.object().shape({
    OrganizationID: Yup.string()
        .required('Organization ID is required')
        .test('unique-organization-id', 'Organization ID already exists', async (value) => {
            if (!value || isEdit) return true;

            try {
                const response = await locationsService.checkOrganizationIDUnique(value);
                return !response.exists;
            } catch (error) {
                console.error('Error checking OrganizationID uniqueness:', error);
                return false;
            }
        }),
    Name: Yup.string().required('Location Name is required'),
    Address1: Yup.string().required('Address 1 is required'),
    City: Yup.string().required('City is required'),
    State: Yup.string().required('State is required'),
    ZipCode: Yup.string().required('ZipCode is required'),
    Country: Yup.string().required('Country is required'),
    ContactName: Yup.string().required('Contact Name is required'),
    ContactPhone: Yup.string().required('Contact Phone is required'),
    ContactEmail: Yup.string().required('Contact Email is required'),
    TimeZone: Yup.string().required('Time zone is required'),

});

interface Props {
    branchesData?: ILocationsDetails; // Location data for editing
}

const LocationsAdd: FC<Props> = ({ branchesData }) => {
    const [loading, setLoading] = useState(false);
    const { branchId } = useParams<{ branchId: string }>();
    const [companyList, setCompanyList] = useState<ClientsDto[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const data = await clientsService.getAllClients(1, 10);
            const selectedAccount = localStorage.getItem('selectedAccount');
            const selectedCompany = selectedAccount ? selectedAccount : '';
            setCompanyList(data.clients);
            formik.setFieldValue('AccountID', selectedCompany);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    useEffect(() => {
        const fetchLocationDetails = async () => {
            setLoading(true);
            try {
                const response = await locationsService.getLocationsById(branchId);
                formik.setValues(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching location details:', error);
                setLoading(false);
            }
        };

        if (branchId) {
            fetchLocationDetails();
        }
    }, [branchId]);

    const initialValuesForForm = branchesData || { ...initialValues, IsActive: !branchId };

    const formik = useFormik<ILocationsDetails>({
        initialValues: initialValuesForForm,
        validationSchema: clientDetailsSchema(!!branchId),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                if (branchId) {
                    values.ID = Number(branchId);
                }
                await locationsService.locationsAddEdit(values);
                formik.resetForm();
                setLoading(false);
                navigate('/locations');
            } catch (error) {
                console.error('Error adding/editing Location:', error);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        window.addEventListener('storage', fetchClients);
        return () => {
            window.removeEventListener('storage', fetchClients);
        };
    }, []);

    return (
        <div className='row gy-5 gx-xl-8'>
            <div className='col-xl-12'>
                <form onSubmit={formik.handleSubmit} noValidate className='form' style={{ backgroundColor: 'white' }}>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company</label>
                            <div className='col-lg-8 fv-row'>
                                <select className='form-select form-select-solid form-select-lg' {...formik.getFieldProps('AccountID')} disabled>
                                    <option value="">Select Company</option>
                                    {companyList.map((cmp) => (
                                        <option key={cmp.ID} value={cmp.ID}>
                                            {cmp.CompanyName}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.AccountID && formik.errors.AccountID && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.AccountID}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Location Name</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('Name')}
                                />
                                {formik.touched.Name && formik.errors.Name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.Name}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address 1</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('Address1')}
                                />
                                {formik.touched.Address1 && formik.errors.Address1 && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.Address1}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Address 2</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('Address2')}
                                />
                                {formik.touched.Address2 && formik.errors.Address2 && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.Address2}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>City</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('City')}
                                />
                                {formik.touched.City && formik.errors.City && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.City}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>State</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('State')}
                                />
                                {formik.touched.State && formik.errors.State && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.State}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>ZipCode</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('ZipCode')}
                                />
                                {formik.touched.ZipCode && formik.errors.ZipCode && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.ZipCode}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('Country')}
                                />
                                {formik.touched.Country && formik.errors.Country && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.Country}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contact Name</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('ContactName')}
                                />
                                {formik.touched.ContactName && formik.errors.ContactName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.ContactName}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contact Phone</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('ContactPhone')}
                                />
                                {formik.touched.ContactPhone && formik.errors.ContactPhone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.ContactPhone}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contact Email</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('ContactEmail')}
                                />
                                {formik.touched.ContactEmail && formik.errors.ContactEmail && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.ContactEmail}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Organization ID</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid text-uppercase'
                                    {...formik.getFieldProps('OrganizationID')}
                                />
                                {formik.touched.OrganizationID && formik.errors.OrganizationID && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.OrganizationID}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Time Zone</label>

                            <div className='col-lg-8 fv-row'>
                                <TimezoneSelect
                                    value={formik.values.TimeZone ? JSON.parse(formik.values.TimeZone) : ''}
                                    onChange={(value) => formik.setFieldValue('TimeZone', JSON.stringify(value))}
                                    className='form-select form-select-solid form-select-lg'
                                />
                                {formik.touched.TimeZone && formik.errors.TimeZone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.TimeZone}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {branchId && (
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className=''>Status</span>
                                </label>
                                <div className='col-lg-8 d-flex align-items-center'>
                                    <div className='form-check form-switch fv-row'>
                                        <input
                                            className='form-check-input w-45px h-30px'
                                            type='checkbox'
                                            id='allowmarketing'
                                            checked={formik.values.IsActive}
                                            onChange={(e) => {
                                                formik.setFieldValue('IsActive', e.target.checked);
                                            }}
                                        />
                                        <label className='form-check-label'></label>
                                        {formik.touched.IsActive && formik.errors.IsActive && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.IsActive}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export { LocationsAdd };
