/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { VisitorTypes } from '../../../../app/modules/apps/invites/components/VisitorModel'
import { dashboardService } from '../../../../services/dashboard.service'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
    className: string
    image: string
    title: string
    time: string
    description: string
    visitorTypes: VisitorTypes[] // New prop for visitor types
}

const StatisticsWidgetVisitors: React.FC<Props> = ({ className, image, title, time, description, visitorTypes }) => {

    const [selectedVisitorType, setSelectedVisitorType] = useState<string>('');
    const [visitorsCount, setVisitorsCounts] = useState<string>('');
    const { currentUser, logout } = useAuth()

    const handleVisitorTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedVisitorType(e.target.value);
        if (Number(e.target.value) > 0) {
            try {
                const storedOrganization = localStorage.getItem('selectedOrganization');
                const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
                const data = await dashboardService.getVisitorsFilter(e.target.value, OrgId);
                setVisitorsCounts(data.visitorsCounts);
            } catch (error) {
                console.error('Error fetching VisitorActivity:', error);
            }
        }
    };
    return (
        <div
            className={`card bgi-no-repeat ${className}`}
            style={{
                backgroundPosition: 'right top',
                backgroundSize: '30% auto',
                backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
            }}
        >
            {/* begin::Body */}
            <div className='card-body'>
                {visitorTypes.length > 0 && (
                    <select
                        name='status'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select form-select-white form-select-sm position-absolute top-0 end-0 m-5 w-175px'
                        defaultValue=''
                        value={selectedVisitorType}
                        onChange={handleVisitorTypeChange}
                    // onChange={(e) => setSelectedVisitorType(e.target.value)}
                    >
                        <option value=''>ALL</option>
                        {visitorTypes.map((visitorType) => (
                            <option key={visitorType.ID} value={visitorType.ID}>
                                {visitorType.Name}
                            </option>
                        ))}
                    </select>
                )}

                <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-4'>
                    {visitorsCount ? visitorsCount : title}
                </a>

                <div className='fw-bold text-primary my-6'>{time}</div>

                <p
                    className='text-dark-75 fw-semibold fs-5 m-0'
                    dangerouslySetInnerHTML={{ __html: description }}
                ></p>
            </div>
            {/* end::Body */}
        </div>
    )
}

export { StatisticsWidgetVisitors }
