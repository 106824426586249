
export interface Invitation {
    ID: number;
    LocationID: string;
    VisitorID: Number;
    VisitDte: string;
    Purpose: string;
    HostID: string;
    Note: string;
    Email: string;
    IsActive: boolean;
    SentDte: string;
    CreateDte: string;
    visitorName: string;
    FirstName : string;
    LastName : string;
  }
 

  export interface IInvitationDetails{
    ID: number;
    VisitorID: string;
    VisitorTypeID: string;
    HostID : string,
    FirstName : string,
    LastName : string,
    Purpose: string;
    Note: string;
    Email: string;
    OrgId?: string;
  }

  export const invitationDetailsInitValues: IInvitationDetails = {
    ID: 0,
    HostID: '',
    VisitorID: '',
    VisitorTypeID: '',
    Purpose: '',
    Note: '',
    Email: '',
    OrgId:'',
    FirstName : '',
    LastName : '',
  }