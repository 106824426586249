
import { fetchWrapper } from '../helpers';

const API_BASE_URL = process.env.REACT_APP_API_URL

export const locationsService = {
    getAllLocations,
    getLocationsById,
    locationsAddEdit,
    deleteLocations,
    getAllOrganizations,
    checkOrganizationIDUnique
};

async function getAllOrganizations(accountID) {
    return await fetchWrapper.get(`${API_BASE_URL}/locations/getorganizations?accountID=${accountID}`);
}

async function getAllLocations(currentPage, pageSize, orgId, accountId) {
    return await fetchWrapper.get(`${API_BASE_URL}/locations/list?page=${currentPage}&pageSize=${pageSize}&orgId=${orgId}&accountId=${accountId}`);
}

async function getLocationsById(clientId) {
    return await fetchWrapper.get(`${API_BASE_URL}/locations/getbyid?id=${clientId}`); // Use the base URL
}

async function locationsAddEdit(Clients) {
    return await fetchWrapper.post(`${API_BASE_URL}/locations/addedit`, Clients); // Use the base URL
}

async function deleteLocations(clientId) {
    return await fetchWrapper.delete(`${API_BASE_URL}/locations/delete?id=${clientId}}`);
}

async function checkOrganizationIDUnique(orgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/locations/orgexist?org=${orgId}`); // Use the base URL
}