import React, { FC } from 'react'
import { TablesVisitLog } from '../../../../../_metronic/partials/widgets/tables/TablesVisitLog'

const VisitLog: FC = () => {
    return (
        <>
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                    <TablesVisitLog className='card-xxl-stretch mb-5 mb-xl-8' />
                </div>
            </div>
        </>
    )
}

export { VisitLog }