import React, { FC, useEffect, useState } from 'react'  
import { TablesInvitations } from '../../../../../_metronic/partials/widgets/tables/TablesInvitations'
 
const Invitations: FC = () => {
    
    return (
        <>
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                    <TablesInvitations className='card-xxl-stretch mb-5 mb-xl-8' />
                </div>
            </div>
        </>
    )
}

export { Invitations }