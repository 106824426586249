import { BehaviorSubject } from 'rxjs';
import getConfig from 'next/config';

import { fetchWrapper } from '../helpers';
const API_BASE_URL = process.env.REACT_APP_API_URL


export const visitorsLogService = {
    getAllVisitorsLog
};

async function getAllVisitorsLog(currentPage, pageSize, startDate, endDate, OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/visitorslog/list?page=${currentPage}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&OrgId=${OrgId}`);
    // return await fetchWrapper.get(`${API_BASE_URL}/visitorslog/list?page=${currentPage}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`);
}