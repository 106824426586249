import React, { FC, useEffect, useState } from 'react'  
import { TablesVisitors } from '../../../../../_metronic/partials/widgets/tables/TablesVisitors'
 
const Visitors: FC = () => {
    
    return (
        <>
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                    <TablesVisitors className='card-xxl-stretch mb-5 mb-xl-8' />
                </div>
            </div>
        </>
    )
}

export { Visitors }