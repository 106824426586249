import { BehaviorSubject } from 'rxjs';
import getConfig from 'next/config';

import { fetchWrapper } from '../helpers';

//const { publicRuntimeConfig } = getConfig();
const API_BASE_URL = process.env.REACT_APP_API_URL
const userSubject = new BehaviorSubject(typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user')));

export const userService = {
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value },
    getAll
};


async function getAll() {
    return await fetchWrapper.get(API_BASE_URL);
}