import { FC } from "react"
import { TablesLocations } from "../../../_metronic/partials/widgets/tables/TablesLocations"



const LocationsPage: FC = () => {
    return (
        <>
         <div className='row gy-5 gx-xl-8'>
             <div className='col-xl-12'>
                <TablesLocations className='card-xxl-stretch mb-5 mb-xl-8' />
            </div>
        </div>
        </>
    )

}


export {LocationsPage}