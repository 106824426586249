
import { fetchWrapper } from '../helpers';

const API_BASE_URL = process.env.REACT_APP_API_URL



export const visitorActivityService = {
    getAllVisitorActivity,
    getVisitorActivityById,
    visitorActivityAddEdit,
    deleteVisitorActivity
};


async function getAllVisitorActivity(currentPage, pageSize, OrgId) {
    return await fetchWrapper.get(`${API_BASE_URL}/visitoractivity/list?page=${currentPage}&pageSize=${pageSize}&OrgId=${OrgId}`);
}

async function getVisitorActivityById(visitorActivityId) {
    return await fetchWrapper.get(`${API_BASE_URL}/visitoractivity/getbyid?id=${visitorActivityId}`); // Use the base URL
}

async function visitorActivityAddEdit(visitoractivity) {
    return await fetchWrapper.post(`${API_BASE_URL}/visitoractivity/addedit`, visitoractivity); // Use the base URL
}

async function deleteVisitorActivity(visitorActivityId) {
    return await fetchWrapper.delete(`${API_BASE_URL}/visitoractivity/delete?id=${visitorActivityId}}`);
}
