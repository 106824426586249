/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { visitorActivityService } from '../../../../services/visitoractivity.service'
import { VisitorActivity } from '../../../../app/pages/dashboard/Models/VisitorActivity'
import { useAuth } from '../../../../app/modules/auth'
import { format } from 'date-fns';
import { DeleteModal } from '../../modals/delete-modal/DeleteModal'

type Props = {
  className: string
}

const TablesLatestVisitorActivity: React.FC<Props> = ({ className }) => {
  const [VisitorActivities, setVisitorActivity] = useState<VisitorActivity[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const pageSize = 10; // Number of entries per page
  const { currentUser, logout } = useAuth()
  const [visitorActivityToDeleteId, setVisitorActivityToDeleteId] = useState<number | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  useEffect(() => {
    fetchVisitorActivity();
  }, [currentPage]); // Fetch visitors whenever the page changes or the AccessPoints selection changes

  const fetchVisitorActivity = async () => {
    try {
      const storedOrganization = localStorage.getItem('selectedOrganization');
      const OrgId = storedOrganization ? storedOrganization : currentUser?.OrganizationID;
      const data = await visitorActivityService.getAllVisitorActivity(currentPage, pageSize, OrgId);
      setVisitorActivity(data.visitorActivity);
      setTotalEntries(data.totalEntries);
    } catch (error) {
      console.error('Error fetching VisitorActivity:', error);
    }
  };

  const formatDate = (dateString: any) => {
    if (dateString) {
      const date = new Date(dateString);
      return format(date, 'dd MMM yyyy hh:mm aa');
    }
  };

  
  const openDeleteModal = (visitorActivityId: number) => {
    setVisitorActivityToDeleteId(visitorActivityId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      // Call your delete API endpoint here
      if (!visitorActivityToDeleteId) return;

      await visitorActivityService.deleteVisitorActivity(visitorActivityToDeleteId);
      // If successful, remove the deleted access point from the state
      setVisitorActivity((prevVisitorActivity) => prevVisitorActivity.filter((ap) => ap.ID !== visitorActivityToDeleteId));
      setShowDeleteModal(false);
      console.log('visitor Activity deleted successfully');
    } catch (error) {
      console.error('Error deleting visitor Activity:', error);
    }
  };

  return (
    <div className={`card ${className}`}>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
        text = 'Visitor Activity'
      />
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Latest 10 Visits</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>(total {totalEntries})</span>
        </h3>
        <div className='card-toolbar'>

        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Firstname</th>
                <th className='min-w-125px'>Lastname</th>
                <th className='min-w-125px'>Email</th>
                <th className='min-w-125px'>Phone</th>
                <th className='min-w-125px'>Visitor Type</th>
                <th className='min-w-125px'>Access Point</th>
                <th className='min-w-200px  text-center rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {VisitorActivities.map((visitorActivity: VisitorActivity) => (

                <tr key={visitorActivity.ID}>
                  <td>
                    <div className='d-flex align-items-center'>

                      <div className='d-flex justify-content-start flex-column'>

                        <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {visitorActivity.FirstName}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {visitorActivity.LastName}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {visitorActivity.Email}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {visitorActivity.Phone}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {visitorActivity.VisitorTypeName}
                    </span>
                  </td>
                  <td>
                    <a href='javascript:void(0)' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      Checked-in at Frontdesk on
                    </a>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {formatDate(visitorActivity.CheckIn)}
                    </span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='javascript:void(0)'
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                    >
                      Invite
                    </a>

                    <a
                      href={`/visitorActivity/edit/${visitorActivity.ID}`}
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary  btn-sm px-4 me-2'
                    >
                      Edit
                    </a>
                    <a
                      href='javascript:void(0)'
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                      onClick={() => openDeleteModal(visitorActivity.ID)}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesLatestVisitorActivity }
